import PropTypes from "prop-types";
import {useEffect} from "react";
import {Dropdown} from "react-bootstrap";
import {CustomToggle} from "../CustomToggle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";

const SkinsSelect = (props) => {
    const {isMobile, skins, type, setSelectedSkinId, setSelectedSkin} = props;

    useEffect(() => {
    }, [, skins.length]);

    const handleSelect = (skin) => {
        setSelectedSkinId(parseInt(skin?.id));
        setSelectedSkin(skin);
    }

    const renderMobile = () => {
        return (
            <div>
                <Dropdown data-bs-theme="light">
                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="outline-dark" as={CustomToggle}
                                     className={'rounded-my-skins ps-5 pe-5 pt-3 pb-3 roboto text-uppercase'}>
                        <span className={'text-black'}>
                            Filter By {type} Games
                            <FontAwesomeIcon icon={faAngleDown} className={'text-black ms-3'}/>
                        </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={'rounded-4'}>
                        {
                            skins?.map((value) => (
                                renderOption(value)
                            ))
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }

    const renderOption = (skin) => {
        return (
            <Dropdown.Item onClick={() => (handleSelect(skin))} key={`dd_item_${skin?.id}`} id={`dd_item_${skin?.id}`}>
                <div className={'border-bottom pb-3 pt-2'}>
                    <span className={'text-start me-5 pe-5 roboto text-black font-16 fw-bold'}>
                        {skin?.name}
                    </span>
                    <span className={'text-end ms-5 ps-5 font-16 roboto'}>
                        {Intl.NumberFormat("en-GB", {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 0,
                            style: 'currency',
                            currency: 'GBP'
                        }).format(skin?.jackpot)}
                    </span>
                </div>
            </Dropdown.Item>
        );
    }

    const renderDesktop = () => {
        return (
            <div>
                <Dropdown data-bs-theme="light">
                    <Dropdown.Toggle id="dropdown-button-dark-example1" variant="outline-light" as={CustomToggle}
                                     className={'rounded-my-skins ps-5 pe-5 pt-3 pb-3 roboto text-uppercase'}>
                        <span className={'text-white'}>
                            Filter By {type} Games
                            <FontAwesomeIcon icon={faAngleDown} className={'text-white ms-3'}/>
                        </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={'rounded-4'}>
                        {
                            skins?.map((value) => (
                                renderOption(value)
                            ))
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }

    const render = () => {
        if (isMobile) {
            return renderMobile();
        }

        return renderDesktop();
    }

    return render();
}

SkinsSelect.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    skins: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    setSelectedSkinId: PropTypes.func.isRequired,
    setSelectedWeekId: PropTypes.func.isRequired,
    setSelectedTypeId: PropTypes.func.isRequired,
}

export default SkinsSelect;
