import PropTypes from "prop-types";

const PickCardStatusComponent = (props) => {

    const {isMobile, status, backgroundColor, color} = props;

    const renderDesktop = () => {

        return (
            <></>
        );
    }

    const renderMobile = () => {

        return (
            <div className={'justify-content-center d-inline-flex border-0 fw-bold roboto font-18'} style={{
                backgroundColor: backgroundColor,
                color: color,
                borderBottomLeftRadius: '35px',
                borderTopRightRadius: '35px',
                position: 'absolute',
                left: 'auto',
                right: '8px',
                marginTop: '-16px',
                padding: '0.75rem 4rem'
            }}>
                {status}
            </div>
        );
    }

    const render = () => {
        if (isMobile) {
            return renderMobile();
        }
        return renderDesktop();
    }

    return render();
}

PickCardStatusComponent.propTypes = {
    status: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    isMobile: PropTypes.bool.isRequired,
}

export default PickCardStatusComponent;
