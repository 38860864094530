import {Button, CloseButton, Col, Modal, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import SmallLoader from "./SmallLoader";
import {useDispatch, useSelector} from "react-redux";
import {getNumberWithOrdinal} from "../CustomFunctions/getNumberWithOrdinal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {post} from "../Axios/post";
import {STAGING_BASE_URL, STORE_USER_SKIN_PICKS} from "../Axios/EndPoints";
import {useSearchParams} from "react-router-dom";

const SelectedPicksModal = (props) => {

    const dispatch = useDispatch();
    const user = useSelector(state => state.Login);
    const [params] = useSearchParams();

    const {
        showModal,
        setShowModal,
        handleRemovePick,
        pickNumber,
        selectedPicks,
        isMobile,
        showPicks,
        skinId, weekId, isEdit, pickToEdit, setShowAlertModal, availablePicks
    } = props;

    const [loading, setLoading] = useState(false);

    const storePicks = () => {
        setLoading(true);

        let nullPick = {
            match_id: null,
            team_id: null
        };

        let data = {
            skin_id: skinId,
            skin_week_id: weekId,
            user_skin_picks: {
                pick_1: selectedPicks.pick_1 || nullPick,
                pick_2: selectedPicks.pick_2 || nullPick,
                pick_3: selectedPicks.pick_3 || nullPick,
            }
        };

        let isStaging = parseInt(params.get('staging'));
        post(STORE_USER_SKIN_PICKS, data, true, user.token, dispatch, isStaging ? STAGING_BASE_URL : '').then(res => {
            if (res.data.success === true) {
                setShowModal(false);
                setShowAlertModal(true);
            }
        }).catch(error => {
            console.error("error while submitting edit = ", error.response.data.message);
            alert(error.response.data.message);

        });
    }

    useEffect(() => {
    }, [showModal, selectedPicks, pickNumber, availablePicks]);

    const renderPick = (team, number) => {
        return (
            <Col>
                <div className={'d-flex flex-column justify-content-evenly'}>
                    <div className={'justify-content-center d-inline-flex'}>
                      <span
                          className={`bg-skins-teal rounded-circle text-white font-16 text-center`}
                          style={{height: '25px', width: '25px'}}>
                          {number}
                      </span>
                    </div>
                    <div className={'justify-content-center d-inline-flex mt-3 mb-2'}>
                        <img src={team?.icon} alt={team?.name} height={72} width={72}/>
                        <FontAwesomeIcon role={'button'} icon={faCircleXmark} color={'#fff'} size={'2x'}
                                         className={'align-self-end text-skins-light-medium-gray opacity-100 bg-opacity-100 border-black'}
                                         onClick={() => {
                                             !loading && handleRemovePick(team, number)
                                         }}
                                         style={{marginLeft: '-15px', marginBottom: '-5px'}}/>
                    </div>
                    <div className={'justify-content-center d-inline-flex'}>
                        {team.alt_name}
                    </div>
                </div>
            </Col>
        );
    }

    const renderTeams = () => {
        let showPick1 = (selectedPicks?.pick_1 && !isEdit) || (isEdit && selectedPicks?.pick_1 && pickToEdit.number === 1);
        let showPick2 = (selectedPicks?.pick_2 && !isEdit) || (isEdit && selectedPicks?.pick_2 && pickToEdit.number === 2);
        let showPick3 = (selectedPicks?.pick_3 && !isEdit) || (isEdit && selectedPicks?.pick_3 && pickToEdit.number === 3);
        return (
            <Row className={'m-4'}>
                {showPick1 && renderPick(selectedPicks?.pick_1, 1)}
                {showPick2 && renderPick(selectedPicks?.pick_2, 2)}
                {showPick3 && renderPick(selectedPicks?.pick_3, 3)}
            </Row>
        );
    }

    const renderConfirmButton = () => {
        return (
            <div className={'col d-grid'}>
                <Button className={`btn-skins-confirm rounded-pill ${isMobile ? 'pt-2 pb-2' : 'pt-4 pb-4'}`}
                        size={'lg'} onClick={storePicks} disabled={loading}>
                    <SmallLoader loading={loading}/>
                    {
                        !loading &&
                        (
                            <>
                                Confirm
                            </>
                        )
                    }
                </Button>
            </div>
        );
    }

    const renderButtons = () => {
        let countPick1 = (((!isEdit && showPicks?.pick_1) || (isEdit && showPicks?.pick_1 && selectedPicks?.pick_1)) & 1 | 0);
        let countPick2 = (((!isEdit && showPicks?.pick_2) || (isEdit && showPicks?.pick_2 && selectedPicks?.pick_2)) & 1 | 0);
        let countPick3 = (((!isEdit && showPicks?.pick_3) || (isEdit && showPicks?.pick_3 && selectedPicks?.pick_3)) & 1 | 0);
        let maxPicks = countPick1 + countPick2 + countPick3;
        // let maxPicks = ((showPicks?.pick_1 && selectedPicks?.pick_1) & 1 | 0) + ((showPicks?.pick_2 && selectedPicks?.pick_2) & 1 | 0) + ((showPicks?.pick_3 && selectedPicks?.pick_3) & 1 | 0);
        return (
            <Row className={'justify-content-center'}>
                {(pickNumber) <= (maxPicks) && pickToEdit?.number === undefined && <div className={'col d-grid'}>
                    <Button className={`btn-skins-red rounded-pill ${isMobile ? 'pt-2 pb-2' : 'pt-4 pb-4'}`} size={'lg'}
                            onClick={() => setShowModal(false)}>
                        <SmallLoader loading={loading}/>
                        {
                            !loading &&
                            (
                                <>
                                    Make {getNumberWithOrdinal((pickNumber))} Pick
                                </>
                            )
                        }
                    </Button>
                </div>}
                {
                    ((pickNumber) > maxPicks && renderConfirmButton())
                }
            </Row>
        );
    }

    const render = () => {
        return (
            <section>
                <Modal
                    show={showModal}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    contentClassName={'rounded-5'}
                    /*onHide={() => {
                        setShowModal(false)
                    }}*/
                >
                    <Modal.Body className={'text-center'}>
                        <CloseButton className={'float-end'} disabled={loading} onClick={() => setShowModal(false)}/>
                        <div className={'roboto font-20 fw-bold mb-3'}>Selected</div>
                        {renderTeams()}
                        {renderButtons()}
                    </Modal.Body>
                </Modal>
            </section>
        );
    }

    return render();
}

SelectedPicksModal.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
}

export default SelectedPicksModal;
