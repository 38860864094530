import PropTypes from "prop-types";

export const MY_SKIN_SCHEMA = {
    id: PropTypes.number.isRequired,
    uid: PropTypes.string.isRequired,
    season: PropTypes.string.isRequired,
    skin_name: PropTypes.string.isRequired,
    entries: PropTypes.number.isRequired,
    jackpot: PropTypes.string.isRequired,
    game_deadline: PropTypes.string.isRequired,
    skin: PropTypes.shape({
        id: PropTypes.number.isRequired,
        uid: PropTypes.string.isRequired,
        season: PropTypes.string.isRequired,
        skin_name: PropTypes.string.isRequired,
        entries: PropTypes.number.isRequired,
        jackpot: PropTypes.string.isRequired,
        game_deadline: PropTypes.string.isRequired,
        league: PropTypes.shape({
            goalserve_id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            background_color: PropTypes.string.isRequired,
            font_color: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
        })
    }),
    skin_week: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        start_date: PropTypes.string.isRequired,
        end_date: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        game_deadline: PropTypes.string.isRequired,
        league: PropTypes.shape({
            goalserve_id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            background_color: PropTypes.string.isRequired,
            font_color: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
        })
    }),
    league: PropTypes.shape({
        goalserve_id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        background_color: PropTypes.string.isRequired,
        font_color: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
    }),
    user_number_of_picks: PropTypes.number.isRequired,
    picks_available: PropTypes.number.isRequired,
    user_skin_picks: PropTypes.shape({
        pick_1: PropTypes.shape({
            week_id: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            selected_team: PropTypes.number.isRequired,
            match: PropTypes.shape({
                goalserve_id: PropTypes.number.isRequired,
                date: PropTypes.string.isRequired,
                time: PropTypes.string.isRequired,
                state: PropTypes.number.isRequired,
                status: PropTypes.string.isRequired,
                is_postponed: PropTypes.bool.isRequired,
                score: PropTypes.string.isRequired,
                points: PropTypes.number.isRequired,
                local_team: PropTypes.shape({
                    goalserve_id: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                    alt_name: PropTypes.string.isRequired,
                    icon: PropTypes.string.isRequired,
                }),
                visitor_team: PropTypes.shape({
                    goalserve_id: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                    alt_name: PropTypes.string.isRequired,
                    icon: PropTypes.string.isRequired,
                })
            })
        }) || null,
        pick_2: PropTypes.shape({
            week_id: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            selected_team: PropTypes.number.isRequired,
            match: PropTypes.shape({
                goalserve_id: PropTypes.number.isRequired,
                date: PropTypes.string.isRequired,
                time: PropTypes.string.isRequired,
                state: PropTypes.number.isRequired,
                status: PropTypes.string.isRequired,
                is_postponed: PropTypes.bool.isRequired,
                score: PropTypes.string.isRequired,
                points: PropTypes.number.isRequired,
                local_team: PropTypes.shape({
                    goalserve_id: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                    alt_name: PropTypes.string.isRequired,
                    icon: PropTypes.string.isRequired,
                }),
                visitor_team: PropTypes.shape({
                    goalserve_id: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                    alt_name: PropTypes.string.isRequired,
                    icon: PropTypes.string.isRequired,
                })
            })
        }) || null,
        pick_3: PropTypes.shape({
            week_id: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            selected_team: PropTypes.number.isRequired,
            match: PropTypes.shape({
                goalserve_id: PropTypes.number.isRequired,
                date: PropTypes.string.isRequired,
                time: PropTypes.string.isRequired,
                state: PropTypes.number.isRequired,
                status: PropTypes.string.isRequired,
                is_postponed: PropTypes.bool.isRequired,
                score: PropTypes.string.isRequired,
                points: PropTypes.number.isRequired,
                local_team: PropTypes.shape({
                    goalserve_id: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                    alt_name: PropTypes.string.isRequired,
                    icon: PropTypes.string.isRequired,
                }),
                visitor_team: PropTypes.shape({
                    goalserve_id: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                    alt_name: PropTypes.string.isRequired,
                    icon: PropTypes.string.isRequired,
                })
            })
        }) || null
    }),
    winners: PropTypes.number.isRequired,
    losers: PropTypes.number.isRequired,
    remaining: PropTypes.number.isRequired,
    history: PropTypes.array.isRequired
};

export const MY_SKIN_PICK_SCHEMA = {
    week_id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    selected_team: PropTypes.number.isRequired,
    match: PropTypes.shape({
        goalserve_id: PropTypes.number.isRequired,
        date: PropTypes.string.isRequired,
        time: PropTypes.string.isRequired,
        state: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
        is_postponed: PropTypes.bool.isRequired,
        score: PropTypes.string.isRequired,
        points: PropTypes.number.isRequired,
        local_team: PropTypes.shape({
            goalserve_id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            alt_name: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
        }),
        visitor_team: PropTypes.shape({
            goalserve_id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            alt_name: PropTypes.string.isRequired,
            icon: PropTypes.string.isRequired,
        })
    })
};

export const MY_SKIN_PICK_MATCH_SCHEMA = {
    goalserve_id: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    state: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    is_postponed: PropTypes.bool.isRequired,
    score: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired,
    local_team: PropTypes.shape({
        goalserve_id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        alt_name: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
    }),
    visitor_team: PropTypes.shape({
        goalserve_id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        alt_name: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
    })
};

export const MATCH_SCHEMA = {
    id: PropTypes.string.isRequired,
    goalserve_id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    timer: PropTypes.string.isRequired || null,
    season: PropTypes.string.isRequired,
    local_team_score: PropTypes.string.isRequired,
    visitor_team_score: PropTypes.string.isRequired,
    state: PropTypes.number.isRequired,
    win_team_id: PropTypes.number.isRequired,
    total: PropTypes.string.isRequired,
    pen_total: PropTypes.string.isRequired,
    et_total: PropTypes.string.isRequired,
    aggregate_score: PropTypes.string.isRequired,
    datetime: PropTypes.string.isRequired,
    league: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
    }).isRequired,
    local_team: PropTypes.shape({
        id: PropTypes.string.isRequired,
        goalserve_id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        alt_name: PropTypes.string.isRequired,
        abbreviation: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        recent_form: PropTypes.array.isRequired,
        position: PropTypes.string.isRequired,
        number_of_picks: PropTypes.number.isRequired,
        is_used_pick_1: PropTypes.bool.isRequired,
        is_used_pick_2: PropTypes.bool.isRequired,
        is_used_pick_3: PropTypes.bool.isRequired
    }).isRequired,
    visitor_team: PropTypes.shape({
        id: PropTypes.string.isRequired,
        goalserve_id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        alt_name: PropTypes.string.isRequired,
        abbreviation: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        recent_form: PropTypes.array.isRequired,
        position: PropTypes.string.isRequired,
        number_of_picks: PropTypes.number.isRequired,
        is_used_pick_1: PropTypes.bool.isRequired,
        is_used_pick_2: PropTypes.bool.isRequired,
        is_used_pick_3: PropTypes.bool.isRequired
    }).isRequired
};

export const SELECTED_PICKS_SCHEMA = PropTypes.shape({
    pick_1: PropTypes.shape({
        match_id: PropTypes.string.isRequired,
        team_id: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        goalserve_id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        alt_name: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
    }) || null,
    pick_2: PropTypes.shape({
        match_id: PropTypes.string.isRequired,
        team_id: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        goalserve_id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        alt_name: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
    }) || null,
    pick_3: PropTypes.shape({
        match_id: PropTypes.string.isRequired,
        team_id: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        goalserve_id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        alt_name: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
    }) || null,
}) || null;
