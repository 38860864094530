import {LoginActionType} from "../Constants/LoginActionType";

export const setIsLogin = (data) => {
    return {
        type: LoginActionType.SET_IS_LOGIN,
        payload: data,
    };
};
export const setToken = (data) => {
    return {
        type: LoginActionType.SET_TOKEN,
        payload: data,
    };
};
export const setUser = (data) => {
    return {
        type: LoginActionType.SET_USER,
        payload: data,
    };
};
export const logout = () => {
    return {
        type: LoginActionType.LOGOUT,
    };
};
