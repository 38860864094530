import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import RecentForm from "./RecentForm";
import TeamCard from "./TeamCard";
import moment from "moment-timezone";
import {MATCH_STATES} from "../../Constants/Match";
import {useEffect, useState} from "react";
import {MATCH_SCHEMA, SELECTED_PICKS_SCHEMA} from "../../Constants/Schemas/MySkinSchema";
import NumberOfPicks from "./NumberOfPicks";

const Match = (props) => {
    const {
        isMobile,
        match,
        selectedPicks,
        deadline,
        currentWeek,
        handlePick,
        selectionAllowed,
        selectionChanged,
        isEdit,
        pickToEdit, pickNumber
    } = props;

    const [matchStatus, setMatchStatus] = useState('Upcoming');

    useEffect(() => {
        if (MATCH_STATES.UPCOMING.includes(match.state)) {
            setMatchStatus('Upcoming');
        }
        if (MATCH_STATES.IN_PLAY.includes(match.state)) {
            setMatchStatus('InPlay');
        }
        if (MATCH_STATES.FINISHED.includes(match.state)) {
            setMatchStatus('Finished');
        }
    }, [match]);

    useEffect(() => {
    }, [matchStatus, selectedPicks, selectionChanged]);

    const renderDateTimeMobile = () => {
        return (
            <Row className={'align-items-end d-flex h-50 justify-content-center text-center'}>
                <Col>
                    <div className={'text-capitalize text-center text-skins-light-medium-gray roboto font-9'}>
                        {moment(`${match.date}`).format('ddd D MMM')}
                    </div>
                    <div className={'text-capitalize text-center roboto font-16  fw-bold'}>
                        {moment(`${match.date} ${match.time}`).format('H:mm')}
                    </div>
                </Col>
            </Row>
        );
    }

    const renderDateTime = () => {
        if (isMobile) {
            return renderDateTimeMobile();
        }
        return (
            <Row>
                <Col>
                    <div className={'text-capitalize text-center text-skins-light-medium-gray roboto font-14'}>
                        {moment(`${match.date}`).format('ddd D MMM')}
                    </div>
                    <div className={'text-capitalize text-center roboto font-25  fw-bold'}>
                        {moment(`${match.date} ${match.time}`).format('H:mm')}
                    </div>
                </Col>
            </Row>
        );
    }

    const renderTimeScoreMobile = () => {
        return (
            <Row className={'justify-content-end'}>
                <div className={'col-12 w-fit'}>
                    <div className={'text-capitalize text-center text-skins-light-medium-gray roboto font-10'}>
                        {moment(`${match.date}`).format('ddd D MMM')}
                    </div>
                    <div className={'text-capitalize text-center roboto font-14 text-black'}>
                        {match.total}
                    </div>
                    {
                        matchStatus !== 'Upcoming' &&
                        <div className={'text-capitalize text-skins-light-medium-gray roboto font-10'}>
                            {match.status} {matchStatus === 'InPlay' && match.state !== MATCH_STATES.HT ? `'` : ''}
                        </div>
                    }
                </div>
            </Row>
        );
    }

    const renderTimeScore = () => {
        if (isMobile) {
            return renderTimeScoreMobile();
        }
        return (
            <Row>
                <div className={'col-12'}>
                    <div className={'text-capitalize text-center text-skins-light-medium-gray roboto font-14'}>
                        {moment(`${match.date}`).format('ddd D MMM')}
                    </div>
                    <div className={'text-capitalize roboto font-25 mt-2  fw-bold'}>
                        {match.total}
                    </div>
                    {
                        matchStatus !== 'Upcoming' &&
                        <div className={'text-capitalize text-skins-light-medium-gray roboto font-16'}>
                            {match.status} {matchStatus === 'InPlay' && match.state !== MATCH_STATES.HT ? `'` : ''}
                        </div>
                    }
                </div>
            </Row>
        );
    }

    const renderMobile = (dateTimeElement, isMatchSelected, isDeadlineReached) => {
        let isLocalSelected = false;
        let isVisitorSelected = false;
        if ([selectedPicks?.pick_1?.team_id, selectedPicks?.pick_2?.team_id, selectedPicks?.pick_3?.team_id].includes(match.local_team.goalserve_id)) {
            isLocalSelected = true;
        }
        if ([selectedPicks?.pick_1?.team_id, selectedPicks?.pick_2?.team_id, selectedPicks?.pick_3?.team_id].includes(match.visitor_team.goalserve_id)) {
            isVisitorSelected = true;
        }
        return (
            <div className={'bg-white text-black justify-content-center d-flex align-items-center m-2 p-0 rounded-4'}>
                <Container fluid>
                    <Row>
                        <Col className={'d-flex flex-column justify-content-center align-items-center'}>
                            <TeamCard isMobile={isMobile} team={match.local_team} win_team_id={match.win_team_id}
                                      selectedPicks={isMatchSelected ? selectedPicks : null} match={match}
                                      isDeadlineReached={isDeadlineReached} currentWeek={currentWeek}
                                      handlePick={handlePick} selectionAllowed={selectionAllowed}
                                      selectionChanged={selectionChanged}
                                      isDraw={[...MATCH_STATES.IN_PLAY, ...MATCH_STATES.FINISHED].includes(match.state) && match.local_team_score === match.visitor_team_score}
                                      isEdit={isEdit} pickToEdit={pickToEdit} pickNumber={pickNumber}
                            />
                            {
                                isDeadlineReached ?
                                    (!isLocalSelected &&
                                        <NumberOfPicks isMobile={isMobile} team={match.local_team} match={match}/>) :
                                    <RecentForm isMobile={isMobile} recent_form={match.local_team.recent_form}/>
                            }
                        </Col>
                        <Col
                            className={'justify-content-center align-items-center text-center col-2 d-flex flex-column'}>
                            {dateTimeElement}
                            {![...MATCH_STATES.IN_PLAY, ...MATCH_STATES.FINISHED].includes(match.state) && <div
                                className={'btn roboto font-12 border-0 rounded-pill ps-4 pe-4 align-items-center d-flex h-25 justify-content-center text-center mt-3 bg-skins-topaz_ bg-transparent text-white'}>
                                STATS
                            </div>}
                        </Col>
                        <Col className={'d-flex flex-column justify-content-center align-items-center'}>
                            <TeamCard isMobile={isMobile} team={match.visitor_team} win_team_id={match.win_team_id}
                                      selectedPicks={isMatchSelected ? selectedPicks : null} match={match}
                                      isDeadlineReached={isDeadlineReached} currentWeek={currentWeek}
                                      handlePick={handlePick} selectionAllowed={selectionAllowed}
                                      selectionChanged={selectionChanged}
                                      isDraw={[...MATCH_STATES.IN_PLAY, ...MATCH_STATES.FINISHED].includes(match.state) && match.local_team_score === match.visitor_team_score}
                                      isEdit={isEdit} pickToEdit={pickToEdit} pickNumber={pickNumber}
                            />
                            {
                                isDeadlineReached ?
                                    (!isVisitorSelected &&
                                        <NumberOfPicks isMobile={isMobile} team={match.visitor_team} match={match}/>) :
                                    <RecentForm isMobile={isMobile} recent_form={match.visitor_team.recent_form}/>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }

    const renderDesktop = (dateTimeElement, isMatchSelected, isDeadlineReached) => {
        return (
            <div className={'bg-white text-black justify-content-center d-flex align-items-center m-3 p-2 rounded-4'}>
                <Container fluid>
                    <Row>
                        <div className={'col-2 justify-content-start align-items-center text-start d-flex'}>
                            {
                                isDeadlineReached ?
                                    <NumberOfPicks isMobile={isMobile} team={match.local_team} match={match}/> :
                                    <RecentForm isMobile={isMobile} recent_form={match.local_team.recent_form}/>
                            }
                        </div>
                        <Col>
                            <TeamCard isMobile={isMobile} team={match.local_team} win_team_id={match.win_team_id}
                                      selectedPicks={isMatchSelected ? selectedPicks : null} match={match}
                                      isDeadlineReached={isDeadlineReached} currentWeek={currentWeek}
                                      handlePick={handlePick} selectionAllowed={selectionAllowed}
                                      selectionChanged={selectionChanged}
                                      isDraw={[...MATCH_STATES.IN_PLAY, ...MATCH_STATES.FINISHED].includes(match.state) && match.local_team_score === match.visitor_team_score}
                                      isEdit={isEdit} pickToEdit={pickToEdit} pickNumber={pickNumber}
                            />
                        </Col>
                        <div className={'w-fit justify-content-center align-items-center text-center d-flex'}>
                            {dateTimeElement}
                        </div>
                        <Col>
                            <TeamCard isMobile={isMobile} team={match.visitor_team} win_team_id={match.win_team_id}
                                      selectedPicks={isMatchSelected ? selectedPicks : null} match={match}
                                      isDeadlineReached={isDeadlineReached} currentWeek={currentWeek}
                                      handlePick={handlePick} selectionAllowed={selectionAllowed}
                                      selectionChanged={selectionChanged}
                                      isDraw={[...MATCH_STATES.IN_PLAY, ...MATCH_STATES.FINISHED].includes(match.state) && match.local_team_score === match.visitor_team_score}
                                      isEdit={isEdit} pickToEdit={pickToEdit} pickNumber={pickNumber}
                            />
                        </Col>
                        <div className={'col-2 justify-content-end align-items-center text-end d-flex'}>
                            {
                                isDeadlineReached ?
                                    <NumberOfPicks isMobile={isMobile} team={match.visitor_team} match={match}/> :
                                    <RecentForm isMobile={isMobile} recent_form={match.visitor_team.recent_form}/>
                            }
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }

    const render = () => {
        let dateTimeElement;
        if (MATCH_STATES.UPCOMING.includes(match.state)) {
            dateTimeElement = renderDateTime();
        }
        if ([...MATCH_STATES.IN_PLAY, ...MATCH_STATES.FINISHED].includes(match.state)) {
            dateTimeElement = renderTimeScore(match.total);
        }
        let isMatchSelected = false;

        if ([selectedPicks?.pick_1?.match_id, selectedPicks?.pick_2?.match_id, selectedPicks?.pick_3?.match_id].includes(match.goalserve_id)) {
            isMatchSelected = true;
        }
        const nowTime = moment().tz("Europe/London").format("YYYY-MM-DDTHH:mm:ss");
        // const nowTime = moment('2023-09-23 14:05:00').tz("Europe/London").format("YYYY-MM-DDTHH:mm:ss");
        const deadlineTime = moment(deadline).format("YYYY-MM-DDTHH:mm:ss");
        let isDeadlineReached = nowTime >= deadlineTime;
        if (isMobile) {
            return renderMobile(dateTimeElement, isMatchSelected, isDeadlineReached);
        }

        return renderDesktop(dateTimeElement, isMatchSelected, isDeadlineReached);
    }

    return render();
}

Match.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    currentWeek: PropTypes.bool.isRequired,
    selectionAllowed: PropTypes.bool.isRequired,
    deadline: PropTypes.string.isRequired || null || undefined,
    match: PropTypes.shape(MATCH_SCHEMA).isRequired,
    selectedPicks: SELECTED_PICKS_SCHEMA,
}

export default Match;
