import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import {Helmet} from "react-helmet";

const CookiePolicy = () => {
    const render = () => {
        return (
            <div className={'w-100'}>
                <Helmet>
                    <title>Sportskins - Cookies Policy</title>
                    <meta name="description" content="Sportskins- Cookies Policy"/>
                </Helmet>
                <Container>
                    <Row>
                        <div className={'text-center fw-bold font-48 text-white cabin mt-5 mb-5'} role={'heading'}
                             aria-level={1}>
                            Cookies Policy
                        </div>
                    </Row>
                </Container>
                <section className={`bg-team-card-grey pt-5 ps-5 pe-5 mt-0 rounded-my-skins-top`}
                         aria-label={'SportSkins Game Rules'}>
                    <Container
                        className={'bg-white p-5 mt-0 rounded-my-skins-top'}>
                        <h2 className={'cabin font-48 fw-bold'}>
                            Our use of cookies:
                        </h2>
                        <p className={'cabin font-19 fw-500 p-2'}>
                            Welcome to sportskins.uk (“Site”). Our website may use cookies to distinguish you from other
                            users of our Site. This helps us to provide you with a good experience when you browse our
                            website and also allows us to improve our Site. Here you will find information on what
                            cookies may be set when you visit our Site and the purposes for which we use those cookies.
                            The Site is owned and operated by Community Gaming Limited (company number13156882 ) (‘we’,
                            ‘our’, ‘us’ as applicable). For further detailed information about this Site, please see our
                            Privacy Policy.
                        </p>
                        <h2 className={'cabin font-48 fw-bold'}>
                            What are cookies?
                        </h2>
                        <p className={'cabin font-19 fw-500 p-2'}>
                            A cookie is a small file of letters and numbers that we store on your browser or the hard
                            drive of your computer if you agree. Cookies contain information that is transferred to your
                            computer’s hard drive.
                            We may use the following cookies in relation to your use of our services:
                            <ul>
                                <li>
                                    Strictly necessary cookies. These are cookies that are required for the operation of
                                    our website. They include, for example, cookies that enable you to log into secure
                                    areas of our website and make use of payment services.
                                </li>
                                <li>
                                    Analytical/performance cookies. They allow us to recognise and count the number of
                                    visitors and to see how visitors move around our website when they are using it.
                                    This helps us to improve the way our website works, for example, by ensuring that
                                    users are finding what they are looking for easily.
                                </li>
                                <li>
                                    Functionality cookies. These are used to recognise you when you return to our
                                    website. This enables us to personalise our content for you, greet you by name and
                                    remember your preferences (for example, your choice of language or region).
                                </li>
                                <li>
                                    Targeting cookies. These cookies record your visit to our website, the pages you
                                    have visited and the links you have followed. We will use this information to make
                                    our website and any advertising displayed on it more relevant to your interests. We
                                    may also share this information with third parties for this purpose.
                                </li>
                            </ul>
                            <br/>
                            Please note that third parties (including, for example, advertising networks and providers
                            of external services like web traffic analysis services) may also use cookies, over which we
                            have no control. These cookies are likely to be analytical/performance cookies or targeting
                            cookies
                        </p>
                        <h2 className={'cabin font-48 fw-bold'}>
                            How to control and delete cookies:
                        </h2>
                        <p className={'cabin font-19 fw-500 p-2'}>
                            <ol>
                                <li>
                                    When you accessed the Site, you were presented with a message that notified you of
                                    our use of cookies. We had to use a cookie to present you with that notice. If you
                                    continue to use the Site, more cookies and other tracking technologies will be
                                    placed on your computer as described in this Cookie Policy in order to enhance your
                                    user experience whilst on the Site. By continuing to use the Site, you are agreeing
                                    to the use of such cookies and tracking technology.
                                </li>
                                <li>
                                    If you wish to restrict or block the cookies which are set by this Site (or, indeed,
                                    on any other site) you can do this through your browser settings. The ‘Help’
                                    function within your browser should tell you how. If you are primarily concerned
                                    about third party cookies generated by advertisers, you can turn these off by going
                                    to the Your Online Choices site at
                                    <a className={'ps-2 pe-2 text-skins-red'}
                                       href={'https://www.youronlinechoices.com/uk/your-ad-choices'} target={'_blank'}
                                       rel={'noreferrer'}>
                                        https://www.youronlinechoices.com/uk/your-ad-choices
                                    </a>.
                                </li>
                                <li>
                                    Alternatively, you may wish to visit
                                    <a className={'ps-2 pe-2 text-skins-red'} href={'https://www.allaboutcookies.org/'}
                                       target={'_blank'} rel={'noreferrer'}>
                                        https://www.allaboutcookies.org/
                                    </a>
                                    which contains
                                    comprehensive information on how to do this on a wide variety of browsers. You will
                                    also find details on how to delete cookies from your computer, as well as more
                                    general information about cookies. Please note that, as these websites are not owned
                                    or operated by us, we are not responsible for any of the content on them.
                                </li>
                                <li>
                                    Please be aware that restricting cookies may mean that you will not be able to take
                                    full advantage of all the features or services available on this Site.
                                </li>
                            </ol>
                            <br/>
                            By accessing, continuing to use or navigating through CGL/Sportskins you accept that we will
                            use certain browser cookies to improve your customer experience with us. We only use cookies
                            which will improve your experience and will not interfere with your privacy.
                            <br/>
                            <br/>
                            Except for essential cookies, all cookies will expire after 30 days.
                        </p>
                        <h2 className={'cabin font-48 fw-bold'}>
                            Contact us:
                        </h2>
                        <p className={'cabin font-19 fw-500 p-2'}>
                            If you have any questions or concerns about cookies or your privacy when using this Site,
                            please contact us as follows: By post at Community Gaming Limited, Coopers House, 65a
                            Wingletye lane, Hornchurch, Essex RM11 3AT. By email at
                            <a className={'text-skins-red ps-2 pe-2'}
                               href={'mailto:customerservices@communitygaming.co.uk'}>
                                customerservices@communitygaming.co.uk
                            </a>.
                        </p>
                    </Container>
                </section>
            </div>
        );
    }

    return render();
}

export default CookiePolicy;
