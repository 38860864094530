import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import EuroGameRules from "../Components/EuroComponents/EuroGameRules/EuroGameRules";

const GameRules = (props) => {
    const {isMobile} = props;
    const render = () => {
        return (
            <div className={'w-100'}>
                <Helmet>
                    <title>Sportskins - Game Rules</title>
                    <meta name="description" content="Sportskins- Game Rules"/>
                </Helmet>
                <Container>
                    <Row>
                        <div className={'text-center fw-bold font-48 text-white cabin mt-5 mb-5'} role={'heading'}
                             aria-level={1}>
                            Game Rules
                        </div>
                    </Row>
                </Container>
                <section className={`bg-team-card-grey p-xxl-5 p-xl-5 p-lg-5 p-md-5 p-sm-1 mt-0 rounded-my-skins-top`}
                         aria-label={'SportSkins Game Rules'}>
                    <Container
                        className={'bg-white p-xxl-5 p-xl-5 p-lg-5 p-md-5 p-sm-1 pt-3 mt-0 rounded-my-skins-top'}>
                        <ol className={`game-rules-ol ${isMobile ? 'm-1' : 'm-5'}`}>
                            <li>
                                <strong>RULES OF THE GAMES</strong>
                                <ol>
                                    <li data-level="5.1">
                                        The rules for each Sportskins games are the same, regardless of the league.
                                        <ol>
                                            <li data-level="5.1.1">
                                                A player must register to play on either the 'Sportskins' website or
                                                Mobile Application.
                                            </li>
                                            <li data-level="5.1.3">
                                                The cost is £10.00 per entry and any one customer may enter up to three
                                                separate entries at the beginning of any one game. i.e 1 entry = £10.00,
                                                2 entries =£20.00 and 3 entries =£30.00
                                            </li>
                                            <li data-level="5.1.4">
                                                Each entry is treated as a unique entity.
                                            </li>
                                            <li data-level="5.1.5">
                                                The player must select a team from the relevant fixture list (‘game
                                                week’) to WIN their fixture. If their selection wins, they are through
                                                to the next round. If their selection draws or loses then they are
                                                unfortunately out of that competition.
                                            </li>
                                            <li data-level="5.1.6">
                                                If successful, the player then must repeat the procedure using the next
                                                set of fixtures. However, a team that has already been selected may not
                                                be selected again. If the game reaches 20 gameweeks in ‘Premskins’ or 24
                                                gameweeks in ‘Champskins’ the game will be deemed over and all remaining
                                                players left in the game will share the pot equally. (This is highly
                                                unlikely as a game usually lasts between 10-12 weeks)
                                            </li>
                                            <li data-level="5.1.7">
                                                Selections must be made by the advertised deadline. If for any reason a
                                                player has not selected by the deadline the player will be allotted the
                                                highest placed team in the corresponding league that is available for
                                                them to choose. This is known as the ‘default selection’. If it is the
                                                first round of a new season, then the winner of the league from the
                                                prior season will be the ‘default’ for ‘Premskins’ and the team that
                                                lost the Play- off final the ‘default’ for ‘Champskins’.
                                            </li>
                                            <li data-level="5.1.8">
                                                Once you have made your pick(s), you may change it (them) at any time up
                                                until the advertised deadline. If a game is postponed and you do not
                                                have time to, or do not wish to change your selection, then the Pools
                                                Panel result will be used to determine the outcome.

                                                <a href={"https://footballpools.com/announcements"} target="_blank"
                                                   rel="noopener noreferrer"
                                                   className={"text-decoration-none text-black ms-1"}>www.footballpools.com/announcements.</a>
                                            </li>
                                            <li data-level="5.1.9">
                                                If a fixture is not on the pools coupon, and the match is postponed
                                                after
                                                the prescribed selection deadline and not completed before the closing
                                                of the Game Week, then the game will be deemed void and both teams will
                                                progress to the following Game Week.
                                            </li>
                                            <li data-level="5.1.10">
                                                If a fixture is postponed in play and not completed before the closing
                                                of the Game Week, CGL will use the score at the time of postponement as
                                                the Final Result.
                                            </li>
                                            <li data-level="5.1.11">
                                                Any fixture that is played out of sequence, due to previous postponement
                                                or rescheduling will not be included and a replacement game will be
                                                added to that game week if necessary.
                                            </li>
                                            <li data-level="5.1.12">
                                                There has to be a minimum of 8 Premier League fixtures (Premskins) and 9
                                                Championship fixtures (Champskins) for a game week to be valid. For
                                                ‘Premskins’ the outstanding fixtures will be replaced by games from the
                                                Championship that CGL deem to be as close to replication to the original
                                                fixture as possible. For example, if the original fixture was between a
                                                team in second place versus a team in fifteenth, we would look for the
                                                fixture that most resembles that from the League below. For ‘Champskins’
                                                CGL will use League 1.
                                                <br/>
                                                If there are less than 8 or 9 fixtures respectively the game week will
                                                generally be voided. However, there may be a scenario where no remaining
                                                player could pick from the postponed fixtures anyway and this will then
                                                be dealt with on a case by case basis.
                                            </li>
                                            <li data-level="5.1.13">
                                                There must be a minimum of 95% of the field eliminated to get a
                                                winner(s). If the highly unlikely situation arises where this does not
                                                occur the prize pot will roll over to the next game. For example if
                                                there are 5000 entries we have to get to the final 250 or fewer to get a
                                                winner. However, if there are greater than 5% of the field remaining at
                                                the season’s close ,i.e 251+ using the previous example, then the prize
                                                pot will get split between the remaining players.
                                            </li>
                                            <li data-level="5.1.14">
                                                If you reach the final 20 places (or fewer) * then you will share an
                                                equal split of 20% of the prize pot. The game continues and the ultimate
                                                winner receives the remaining 80%.
                                            </li>
                                            <li data-level="5.1.15">
                                                The winner of the game is the player that remains in the game for the
                                                longest time and is the Last Player Standing. A situation may arise
                                                whereby the last players standing all go out at the same time. In this
                                                instance the prize pot will be split equally between those players.
                                                <br/>
                                                *There will always be a minimum of reaching the final 20 places (or
                                                fewer). However, if the prize pot is:
                                                <br/>
                                                <br/>
                                                Between £50001 to £75000 total prize pot; reaching the final 30 or
                                                fewer.
                                                <br/>
                                                <br/>
                                                Between £75001 to £100000 total prize pot; reaching the final 50 or
                                                fewer.
                                                <br/>
                                                <br/>
                                                Above £100001 total prize pot; reaching the final 100 or fewer.
                                                <br/>
                                            </li>
                                            <li data-level="5.1.16">
                                            Splitting the pot:
                                                <br/>
                                                If the total pot is above £100,000 then the last 10 (or fewer) players remaining, if all in agreement, may split the pot. If the total pot is between £75,001-£100,000 then the last 5 (or fewer) players remaining, if all in agreement, may split the pot.
                                                <br/>
                                                <br/>
                                                If the total pot is between £20,001-£75,000 then the last 3 (or fewer) players remaining, if all in agreement, may split the pot.
                                                fewer.
                                                <br/>
                                                <br/>
                                                If the pot is below £20,000 then the last 2 players remaining , if both are in agreement may split the pot.
                                                <br/>
                                                <br/>
                                                **Please note that ALL PLAYERS have to be in agreement. If one or more doesn’t agree to the split then the game goes on.
                                                <br/>
                                                <br/>
                                                If the situation arises, all players involved must contact sean@communitygaming.co.uk to express their desire to split the pot.
                                                <br/>

                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                            <li data-level="5.2">
                                Community Gaming Ltd, if necessary to do so, reserve the right to suspend, amend, void
                                or even cancel a game. This may be necessary for reasons beyond our reasonable control
                                including, but not limited to strike, labour dispute, illness, act of God, natural
                                disaster, adverse weather conditions, war, riot, accident, public health concern in
                                relation to potential infection, epidemic or pandemic, malicious damage, fire, flood
                                and/or storm, compliance with law or governmental order, rule, regulation or direction,
                                breakdown of plant, machinery or transportation. Community Gaming Ltd are not
                                responsible for any loss or damage caused to you as a result of any of these
                                occurrences.
                            </li>
                        </ol>
                        <div>
                            <EuroGameRules isMobile={isMobile}/>
                        </div>
                    </Container>

                </section>

            </div>
        );
    };

    return render();
}


GameRules.propTypes = {
    isMobile: PropTypes.bool.isRequired
};
export default GameRules;
