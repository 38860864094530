import PropTypes from "prop-types";

const PrizeComponent = (props) => {

    const {isMobile, text} = props;

    const renderMobile = () => {
        return (
            <span className={'bebas text-white text-capitalize font-30 fw-bold'}>
                {Intl.NumberFormat("en-GB", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 0,
                    style: 'currency',
                    currency: 'GBP'
                }).format(text)}
            </span>
        );
    }

    const renderDesktop = () => {
        return (
            <div
                className={'bg-transparent border-medium-white text-white p-2 rounded-pill w-fit ps-3 pe-3 text-center justify-content-center d-flex align-items-center'}>
                <span className={'roboto text-capitalize font-25 fw-bold'}>
                    {Intl.NumberFormat("en-GB", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 0,
                        style: 'currency',
                        currency: 'GBP'
                    }).format(text)}
                </span>
                &nbsp;&nbsp;&nbsp;
                <span className={'roboto text-uppercase font-12 fw-bold pt-1'}>
                    Prize
                </span>
            </div>
        );
    }

    const render = () => {
        if (isMobile) {
            return renderMobile();
        }
        return renderDesktop();
    }

    return render();
}

PrizeComponent.propTypes = {
    text: PropTypes.number.isRequired,
    isMobile: PropTypes.bool.isRequired,
    winner: PropTypes.bool,
}

export default PrizeComponent;
