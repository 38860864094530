import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";
import {MATCH_STATES} from "../../Constants/Match";

const NumberOfPicks = (props) => {
    const {isMobile, team, match, showInTeamCard} = props;

    const renderMobile = (isWin, isLost) => {
        return (
            <div
                className={`${team.number_of_picks === 0 ? 'bg-team-card-grey text-black' : (isWin ? 'bg-skins-winning text-white' : (isLost ? 'bg-skins-losing text-white' : 'bg-black text-white'))} 
                text-center ps-2 pe-2 justify-content-center d-flex align-items-center rounded-pill roboto font-12 ${showInTeamCard ? 'w-100 mt-2 mb-0' :'w-fit mb-2'}`}>
                {team.number_of_picks}
                {isWin && team.number_of_picks > 0 &&
                    <FontAwesomeIcon icon={faCaretUp} size={'1x'} className={'ms-1'}/>}
                {isLost && team.number_of_picks > 0 &&
                    <FontAwesomeIcon icon={faCaretDown} size={'1x'} className={'ms-1'}/>}
            </div>
        );
    }
    const renderDesktop = (isWin, isLost) => {
        return (
            <div
                className={`${team.number_of_picks === 0 ? 'bg-team-card-grey text-black' : (isWin ? 'bg-skins-winning text-white' : (isLost ? 'bg-skins-losing text-white' : 'bg-black text-white'))} 
                col text-center pt-1 pb-1 justify-content-center d-flex align-items-center rounded-pill roboto font-25`}>
                {team.number_of_picks}
                {isWin && team.number_of_picks > 0 &&
                    <FontAwesomeIcon icon={faCaretUp} size={'2x'} className={'ms-2 mt-2'}/>}
                {isLost && team.number_of_picks > 0 &&
                    <FontAwesomeIcon icon={faCaretDown} size={'2x'} className={'ms-2 mb-1'}/>}
            </div>
        );
    }
    const render = () => {
        let isWin = [...MATCH_STATES.IN_PLAY, ...MATCH_STATES.FINISHED].includes(match.state) && match.win_team_id === parseInt(team.goalserve_id);
        let isLost = [...MATCH_STATES.IN_PLAY, ...MATCH_STATES.FINISHED].includes(match.state) && match.win_team_id !== parseInt(team.goalserve_id);
        if (isMobile) {
            return renderMobile(isWin, isLost);
        }

        return renderDesktop(isWin, isLost);
    }

    return render();
}

NumberOfPicks.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    showInTeamCard: PropTypes.bool.isRequired,
    team: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired || null,
}

NumberOfPicks.defaultProps = {
    showInTeamCard: false,
}

export default NumberOfPicks;
