import Container from "react-bootstrap/Container";
import {Row} from "react-bootstrap";
import {Helmet} from "react-helmet";
import EuroTermsAndConditions from "../Components/EuroComponents/EuroTermsAndConditions/EuroTermsAndConditions";

const TermsConditions = () => {

    const handleScroll = (itemId) => {
        const id = itemId;
        const yOffset = -175;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    }
    const render = () => {
        return (
            <div className={'w-100'}>
                <Helmet>
                    <title>Sportskins - Terms & Conditions</title>
                    <meta name="description" content="Sportskins- Terms & Conditions"/>
                </Helmet>
                <Container>
                    <Row>
                        <h1 className={'text-center fw-bold font-48 text-white syne mt-5 mb-5'}>
                            Terms and Conditions
                        </h1>
                    </Row>
                </Container>
                <section className={`bg-team-card-grey pt-5 ps-5 pe-5 mt-0 rounded-my-skins-top`}
                         aria-label={'SportSkins Game Rules'}>
                    <Container className={'p-5 mt-0 rounded-my-skins-top'}>
                        <div className={'syne font-19 text-black text-uppercase'}>
                            Index
                        </div>
                        <br/>
                        <div>
                            <ol className={'syne font-19 text-black'}>
                                <li role={'button'} onClick={() => handleScroll('introduction')}>Introduction</li>
                                <li role={'button'} onClick={() => handleScroll('terms_conditions')}>Terms and
                                    Conditions
                                </li>
                                <li role={'button'} onClick={() => handleScroll('account_opening')}>Account Opening</li>
                                <li role={'button'} onClick={() => handleScroll('entry_fees')}>Entry Fees</li>
                                <li role={'button'} onClick={() => handleScroll('rules')}>Rules</li>
                                <li role={'button'} onClick={() => handleScroll('ip')}>Intellectual Property</li>
                                <li role={'button'} onClick={() => handleScroll('remote_gaming')}>Remote Gaming</li>
                                <li role={'button'} onClick={() => handleScroll('responsible_gambling')}>Responsible
                                    Gambling
                                </li>
                                <li role={'button'} onClick={() => handleScroll('self_exclusion')}>Self Exclusion</li>
                                <li role={'button'} onClick={() => handleScroll('complaints')}>Complaints, Disputes,
                                    Governing Law and Jurisdiction
                                </li>
                            </ol>
                        </div>
                        <br/>

                        <p id={'introduction'}><strong>1.Introduction</strong></p>
                        <p><span
                            className={'fw-400'}>Sportskins (&lsquo;games&rsquo;) are brought to you by Community Gaming Limited ( &lsquo;Community Gaming&rsquo;, &lsquo;we&rsquo;, &lsquo;us&rsquo; or &lsquo;our&rsquo;).</span>
                        </p>
                        <p><span className={'fw-400'}>Community Gaming is licensed by the Gambling Commission of Great Britain under account 58494</span>
                        </p>
                        <p><span className={'fw-400'}>Community Gaming Limited registered office is at Second Floor, Juniper House, Warley Hill Business Park, Brentwood, CM13 3BE. Company registered number is 13156882.</span>
                        </p>
                        <p><span
                            className={'fw-400'}>These Terms and Conditions of Service (&lsquo;Terms and Conditions&rsquo;, &lsquo;Terms&rsquo;) lay out the Terms on which you are allowed to access and play Sportskins products. The games may be accessed via our mobile application (&lsquo;App&rsquo;) or website.</span>
                        </p>
                        <p><span className={'fw-400'}>Reference to &lsquo;you&rsquo; or &lsquo;your&rsquo; is reference to any person that uses the services of Community Gaming Ltd or any registered customer of Community Gaming Limited.</span>
                        </p>
                        <p><span className={'fw-400'}>The Terms can be accessed via the App and or via our website at www.sportskins.uk (the &lsquo;website&rsquo;)</span>
                        </p>

                        <p id={'terms_conditions'}><strong>2.Terms and Conditions</strong></p>
                        <p><strong>2.1</strong><span className={'fw-400'}> By using the service via the website and/or the App (including, but not limited to sub domains and/or API&rsquo;s ) you confirm that you have read and are legally bound by (i) our Terms and Conditions of the games; (ii) our Privacy Policy;(iii) our Cookies Policy and (iv) the rules of our games (v) you have the mental capacity to take responsibility for you own actions and decision making, and can enter into this contract with us. (vi) You accept full responsibility for placing your bets in the knowledge that there is a risk of losing money. (vii) You will not use this service in any way which could cause offence, be considered defamatory, obscene, abusive, unlawful, racist, sexist or in any way discriminatory to any of Community Gaming&rsquo;s staff or other customers.</span>
                        </p>
                        <p><span className={'fw-400'}>Please read these Terms carefully, and if you do not accept the Terms then you should not proceed with opening an account with us or use any of our services. We recommend that you print a copy of the Terms.</span>
                        </p>
                        <p><strong>2.2</strong><span
                            className={'fw-400'}> These Terms are effective from (12</span><span
                            className={'fw-400'}>TH</span><span className={'fw-400'}> Decen 2022) and any previous Terms are cancelled. The Terms may be subject to change from time to time for any number of reasons, including to comply with changes to applicable laws and regulations and any other regulatory requirements. An up to date set of terms and conditions will be available on our website and you are advised to review the Terms on a frequent basis. Most changes will be minor and may be made at any time, but any major change to the Terms will be notified to you via email. If for any reason any changes in Terms are not acceptable to you, then you should close your account with us and cease using our services. If you use our Service after the date that the changes of the Terms come into effect then you will be deemed to have accepted these changes and are happy to continue.</span>
                        </p>
                        <p><strong>2.3</strong><span className={'fw-400'}> It is your sole responsibility to review the Terms and Conditions (including the Game Rules) and any amendments to them each time you use the website and/or mobile Application.</span>
                        </p>
                        <p><strong>2.4</strong><span className={'fw-400'}> Community Gaming Limited operates solely within the UK jurisdiction. The right to access the website and/or the App or use any services associated with the games, may be illegal in certain countries. You are responsible for determining whether accessing the games is compliant with laws that are applicable in your jurisdiction. By agreeing to the Terms you warrant to us that gambling is not illegal in the country/territory that you reside. However, if at any time we believe your use/participation is in contravention of such law we shall be entitled to exclude you from using our services as we see fit and declare void any bets placed by you, in which event no winnings will be paid in relation to such bets.</span>
                        </p>
                        <p><strong>2.5</strong><span className={'fw-400'}> Community Gaming Limited are committed to providing a high level of customer service. Our service is aimed at providing low risk, enjoyable gaming. Included</span><span
                            className={'fw-400'}> in our commitment, Community Gaming is committed to supporting responsible gambling. For further details, please see the responsible gambling page on our website and mobile Application.</span>
                        </p>
                        <p><span className={'fw-400'}>Although Community Gaming will use its reasonable endeavours to enforce its responsible gambling policies, we do not accept any responsibility or liability if you nevertheless continue gambling and/or seek to use the Website/app with the intention of deliberately avoiding the relevant measures in place and/or Community Gaming is unable to enforce its measures/policies for reasons outside of our reasonable control.</span>
                        </p>

                        <p id={'account_opening'}><strong>3. Opening an account</strong></p>
                        <p><span className={'fw-400'}>In order to participate in the games that Community Gaming Ltd provide you will need to open an account. An account may be opened via either our website or mobile application. The applicant must usually be resident in the UK, Northern Ireland or The Channel Islands and your address is subject to verification.</span>
                        </p>
                        <p><strong>3.1</strong><span className={'fw-400'}> When opening an account, you will be required to provide personal details such as, Your name, Date of birth, address, telephone number and email address. You warrant that all the personal details you provide are accurate, correct and complete. If the details are incomplete or inaccurate, we may suspend your account. If any of your personal details change, please inform us as soon as possible so that we may maintain accurate and up to date records. You can change the details provided at registration at any time by editing your Player Account.</span>
                        </p>
                        <p><strong>3.2</strong><span className={'fw-400'}> A customer is only permitted to have one account to use our service and the personal details that are provided will be confirmed through verification checks. We may use third-party companies to carry out these checks on the company&rsquo;s behalf. This process involves checking the details that you disclose against certain databases, both public and private. By entering this process you agree that we may use, record and disclose such personal information, and that the data may be recorded by us or the third party company.</span>
                        </p>
                        <p><span
                            className={'fw-400'}>Customers may not have more than one Account. If we have reason to believe two or more accounts are held by the same person, they will be deemed &lsquo;Duplicate Accounts&rsquo;. The Account most recently registered will be closed. If any winnings have been obtained via a duplicate account Community Gaming Ltd reserve the right to withhold them.</span>
                        </p>
                        <p><strong>3.3</strong><span className={'fw-400'}> All transactions where your username and password have been entered correctly will be regarded as valid, whether or not authorised by you, and we shall not be liable for any claims in the event that you disclose them to anyone. It is your sole responsibility to maintain the confidentiality of your password and you are responsible for all activity that occurs under your username and password. You should notify us of any unauthorised use of your password and any breach of security as soon as you become aware of it.</span>
                        </p>
                        <p><strong>3.4</strong><span className={'fw-400'}> We have a strict 18+ policy and are required to carry out checks to prevent identity theft, fraud and to confirm that customers are old enough to gamble.</span>
                        </p>
                        <p><span className={'fw-400'}>&nbsp;As it is a requirement that all customers are verified, the process will start when all new accounts are registered and also take place periodically during the lifetime of an account, this is so that we can ensure the data we hold is accurate and up to date. Until verification is successfully completed, you will not be able to deposit, bet, or access any gambling products.</span>
                        </p>
                        <p><span className={'fw-400'}>&nbsp;In most cases the checks we have in place will verify automatically but, in some circumstances, we may require additional information. In these instances, the checks are carried out by our certified 3rd party identity checking company, so you may see a message that asks you to verify your account by uploading documents.</span>
                        </p>
                        <p><span className={'fw-400'}>&nbsp;Depending on the type of checks we need to complete, different types of documents are accepted, this includes (but is not limited to)</span>
                        </p>
                        <p><span className={'fw-400'}>&nbsp;</span></p>
                        <ul>
                            <li className={'fw-400'} aria-level="1"><span className={'fw-400'}>Valid photocard driving license (matching address)</span>
                            </li>
                            <li className={'fw-400'} aria-level="1"><span className={'fw-400'}>Valid passport (photo page only)</span>
                            </li>
                            <li className={'fw-400'} aria-level="1"><span className={'fw-400'}>Valid ID card (front and back)</span>
                            </li>
                            <li className={'fw-400'} aria-level="1"><span className={'fw-400'}>Bank/Savings account Statement (issued in the last 3 months)</span>
                            </li>
                            <li className={'fw-400'} aria-level="1"><span className={'fw-400'}>Issue letter from a credit/debit or pre-paid card (issued in the last 3 months)</span>
                            </li>
                            <li className={'fw-400'} aria-level="1"><span
                                className={'fw-400'}>Utility bill &ndash; i.e. mobile phone, water, gas, electric (issued in the last 3 months)</span>
                            </li>
                            <li className={'fw-400'} aria-level="1"><span className={'fw-400'}>Council Tax bill (issued in the current tax year)</span>
                            </li>
                            <li className={'fw-400'} aria-level="1"><span className={'fw-400'}>HMRC tax notification (issued in the last 12 months)</span>
                            </li>
                            <li className={'fw-400'} aria-level="1"><span className={'fw-400'}>Tenancy Agreement (issued in the last 12 months)</span>
                            </li>
                            <li className={'fw-400'} aria-level="1"><span className={'fw-400'}>Mortgage or home loan statement</span>
                            </li>
                            <li className={'fw-400'} aria-level="1"><span className={'fw-400'}>Car, home, mobile phone insurance certificate (issued in the last 12 months)</span>
                            </li>
                            <li className={'fw-400'} aria-level="1"><span className={'fw-400'}>Formal University acceptance or admissions letter (issued in the last 12 months)</span>
                            </li>
                            <li className={'fw-400'} aria-level="1"><span className={'fw-400'}>Catalogue statement (issued in the last 3 months)</span>
                            </li>
                            <li className={'fw-400'} aria-level="1"><span className={'fw-400'}>Contract of employment or payslip with visible address (issued within the last 3 months)</span>
                            </li>
                            <li className={'fw-400'} aria-level="1"><span
                                className={'fw-400'}>Marriage certificate</span>
                            </li>
                        </ul>
                        <p><strong>3.5</strong><span
                            className={'fw-400'}> If your account remains inactive for a continuous period of 365 days, CGL may deem your account to be &lsquo;dormant&rsquo; and your account may be closed. As CGL do not hold any balance on account for customers, there will be no need for any payments or withdrawals to be made.</span>
                        </p>
                        <p><strong>3.6</strong><span className={'fw-400'}> You can &lsquo;re-activate&rsquo; your account at any time by successfully logging in to your account.</span>
                        </p>

                        <p id={'entry_fees'}><strong>4. Entry Fees</strong></p>

                        <p><strong>4.1</strong><span className={'fw-400'}> Community Gaming Ltd uses third-party electronic payment processors to process all financial transactions and you hereby consent to our third-party electronic payment processors handling your transactions.</span>
                        </p>
                        <p><strong>4.2</strong><span className={'fw-400'}> We are required by our licence to inform customers about what happens to funds which we hold on account, and the extent to which funds are protected in the event of insolvency</span><a
                            href="https://www.gamblingcommission.gov.uk/public-and-players/guide/what-happens-to-your-money-if-a-gambling-business-goes-bust"><span
                            className={'fw-400'}> </span><span
                            className={'fw-400'}>https://www.gamblingcommission.gov.uk/public-and-players/guide/what-happens-to-your-money-if-a-gambling-business-goes-bust</span></a>
                        </p>
                        <p><strong>4.3</strong><span className={'fw-400'}> Customer funds are kept in accounts separate from business accounts; and arrangements have been made to ensure assets in the customer prize pot, are distributed equally to customers still involved in the competition in the event of insolvency. This meets the Gambling Commission&rsquo;s requirements for segregation of customer funds at the level: medium protection</span>
                        </p>
                        <p><strong>4.4</strong><span className={'fw-400'}> To play Sportskins games, you will need to pay an entry fee as set out in the Game Rules.</span>
                        </p>
                        <p><span className={'fw-400'}>At Community Gaming Limited we do not hold any customer funds on account for future use. Once an entry fee has been paid it is allocated to the relevant competition. Due to the customer &lsquo;default selection&rsquo; mechanism (Section 5.1.7) ALL funds deposited are allocated to the respective competition and customers will have no balance on account.</span>
                        </p>
                        <p><strong>4.5</strong><span className={'fw-400'}> Once you have entered a new game and it has started, you agree not to charge back or attempt to reverse your entry fee at any stage. Any attempt to do so will be in breach of these Terms and Conditions and you will be eliminated from any Community Gaming Limited games.</span>
                        </p>
                        <p><strong>4.6</strong><span className={'fw-400'}> If you have deposited your entry fee for a particular game, before that game has started you may request a refund up to 24 hours before the designated deadline for the relevant game. If we incur any charges in relation to the refund we reserve the right to charge you the relevant amounts incurred. If you wish to refund your entry contact customerservices@communitygaming.co.uk</span>
                        </p>
                        <p><strong>4.7</strong><span className={'fw-400'}> When you make a deposit, some banks may charge a fee on your transaction. This is your responsibility.</span>
                        </p>
                        <p><strong>4.8</strong><span className={'fw-400'}> Community Gaming Limited take 15% of each entry into their games. All of the administration costs are taken from this percentage and no further costs are passed on to a player.</span>
                        </p>

                        <p id={'rules'}><strong>5. Rules of the games</strong></p>

                        <p><strong>5.1</strong><span className={'fw-400'}> The rules for Sportskins Premier League and Championship football games are the same.&nbsp;</span>
                        </p>
                        <p><strong>5.1.1</strong><span className={'fw-400'}> A player must register to play via the ‘SportSkins’ mobile application</span>
                        </p>
                        <p><strong>5.1.2</strong><span className={'fw-400'}> The required entry fee must be paid via the 'Sportskins' mobile Application only.</span>
                        </p>
                        <p><strong>5.1.3</strong><span className={'fw-400'}> The cost is &pound;10.00 per entry and any one customer may enter up to three separate entries at the beginning of any one game. i.e 1 entry =&pound;10.00, 2 entries =&pound;20.00 and 3 entries =&pound;30.00&nbsp;</span>
                        </p>
                        <p><strong>5.1.4</strong><span
                            className={'fw-400'}> Each entry is treated as a unique entity.&nbsp;</span>
                        </p>
                        <p><strong>5.1.5</strong><span className={'fw-400'}> The player must select a team from the relevant fixture list (‘game week’) to WIN their fixture. If their selection wins, they are through to the next round. If their selection draws or loses then they are unfortunately out of the competition. If a player has multiple entries, they may select the same team for each entry if they wish.&nbsp;</span>
                        </p>
                        <p><strong>5.1.6</strong><span className={'fw-400'}> If successful, the player then must repeat the procedure using the next set of fixtures. However, a team that has already been selected may not be selected again. IF the game was to go 20 game weeks in the Premiership game or 24 game weeks in the Championship game then the game is finished, and any player left standing will receive an equal share of the prize pot. However this is extremely unlikely as a game generally lasts 10-12 game weeks.</span>
                        </p>
                        <p><strong>5.1.7</strong><span className={'fw-400'}> Selections must be made by the advertised deadline. If, for any reason, a player has not selected by the deadline, the player will be allotted the highest placed team in the corresponding league available for them to choose. This is known as the ‘default’ selection.</span>
                        </p>
                        <p><strong>5.1.8</strong><span className={'fw-400'}> Once you have made your pick(s), you may change it (them) at any time up until the advertised deadline.</span>
                        </p>
                        <p><span className={'fw-400'}>If a game is postponed and you do not have time to, or do not wish to change your selection, then the Pools Panel result will be used to determine the outcome. </span><a
                            href="http://www.footballpools.com/announcements"><span
                            className={'fw-400'}>www.footballpools.com/announcements</span></a><span
                            className={'fw-400'}>.</span></p>
                        <p><strong>5.1.9</strong><span className={'fw-400'}> If a fixture is not on the pools coupon, a contingency fixture, that is on the coupon, will be provided in case of postponement. The contingency fixture will be one that CGL deem to be as close a replication to the original fixture as possible. For example, if the original fixture was between a team in second place versus a team in fifteenth, we would look for the fixture that most resembles that from the League below.</span>
                        </p>
                        <p><strong>5.1.10</strong><span className={'fw-400'}> If a player gets through via the contingency fixture, then the original team that was selected will be deemed successful and not be available to pick again. For example, your original pick was Man City and that was postponed the contingency pick of Norwich(for example) win, you would no longer be able to use Man City in that competition.</span>
                        </p>
                        <p><strong>5.1.11</strong><span className={'fw-400'}> Any fixture that is played out of sequence, due to previous postponement or rescheduling will not be included and a replacement game will be added to that game week if necessary.</span>
                        </p>
                        <p><strong>5.1.12 </strong><span className={'fw-400'}>There has to be a minimum of 8 Premier League fixtures(Premskins) and 9 Championship fixtures (Champskins) for a game week to be valid. For &lsquo;Premskins&rsquo; the outstanding fixtures will be replaced by games from the Championship that CGL deem to be as close to replication as in rule 5.1.9, for &lsquo;Champskins&rsquo; the replacement games will be from League 1 following the same criteria as for &lsquo;Premskins&rsquo;. If there are less than 8 or 9 fixtures respectively the game week shall be voided.</span>
                        </p>
                        <p><strong>5.1.13</strong><span className={'fw-400'}> There must be a minimum of 95% of the field eliminated to get a winner(s). If the highly unlikely situation arises where this does not occur the prize pot will roll over to the next game. For example if there are 5000 entries we have to get to the final 250 or fewer to get a winner. However, if there are greater than 5% of the field remaining at the season&rsquo;s close ,i.e 251+ using the previous example, then the prize pot will get split between the remaining players.</span>
                        </p>
                        <p><strong>5.1.14</strong><span className={'fw-400'}> If you reach the final 20 places (or fewer) * then you will share an equal split of 20% of the prize pot. The game continues and the ultimate winner receives the remaining 80%.</span>
                        </p>
                        <p><strong>5.1.15</strong><span className={'fw-400'}> The winner of the game is the player that remains in the game the longest and is the Last Player Standing. A situation may arise whereby the last players remaining all go out at the same time. In this instance the prize pot will be split equally between those players.</span>
                        </p>
                        <p><span className={'fw-400'}>&nbsp;</span></p>
                        <p><span className={'fw-400'}>*There will always be a minimum of reaching the final 20 places (or fewer), however if the total prize pot is:</span>
                        </p>
                        <p><span className={'fw-400'}>Between £50001 to £75000 total prize pot;</span></p>
                        <p><span className={'fw-400'}>Reaching the final 30 or fewer</span></p>
                        <p><span className={'fw-400'}>Between £75001 to £100000 total prize pot;</span></p>
                        <p><span className={'fw-400'}>Reaching the final 50 or fewer</span></p>
                        <p><span className={'fw-400'}>Above £100001 total prize pot;</span></p>
                        <p><span className={'fw-400'}>Reaching the final 100 or fewer</span></p>

                        <p><strong>5.1.16</strong><span className={'fw-400'}>  Splitting the pot:</span>
                        </p>
                        {/* <p><span className={'fw-400'}>&nbsp;</span></p> */}
                        <p><span className={'fw-400'}>If the total pot is above £100,000 then the last 10 (or fewer) players remaining, if all in agreement, may split the pot.
                        If the total pot is between £75,001-£100,000 then the last 5 (or fewer) players remaining, if all in agreement, may split the pot.</span>
                        </p>
                        <p><span className={'fw-400'}>If the total pot is between £20,001-£75,000 then the last 3 (or fewer) players remaining, if all in agreement, may split the pot.</span></p>
                        <p><span className={'fw-400'}>If the pot is below £20,000 then the last 2 players remaining , if both are in agreement may split the pot.
                        </span></p>
                        <p><span className={'fw-400'}>**Please note that ALL REMAINING PLAYERS have to be in agreement. If one or more doesn’t agree to the split then the game goes on.</span></p>
                        <p><span className={'fw-400'}>If the situation arises, all players involved must contact sean@communitygaming.co.uk to express their desire to split the pot.</span></p>
                       
                        <p><strong>5.2</strong><span className={'fw-400'}> Community Gaming Ltd, if necessary to do so, reserve the right to suspend, amend, void or even cancel a game. This may be necessary for reasons beyond our reasonable control including, but not limited to strike, labour dispute, illness, act of God, natural disaster, adverse weather conditions, war, riot, accident, public health concern in relation to potential infection, epidemic or pandemic, malicious damage, fire, flood and/or storm, compliance with law or governmental order, rule, regulation or direction, breakdown of plant, machinery or transportation. Community Gaming Ltd are not responsible for any loss or damage caused to you as a result of any of these occurrences.</span>
                        </p>

                        <p id={'ip'}><strong>6. Intellectual Property</strong></p>

                        <p><strong>6.1</strong><span className={'fw-400'}> You acknowledge and agree that all copyright, trademarks and any other intellectual property rights contained in any material or any content supplied (including any software) by us via the website or the mobile application to you shall at all times remain vested in us or our licensors.</span><span
                            className={'fw-400'}> </span><span className={'fw-400'}>These intellectual property rights include, without limitation, the design, graphics, layout, look and feel and structure of our website, database rights, design rights, domain names and rights to goodwill and/or to sue for passing off. You are permitted to use this material and content only as expressly authorised by us or our licensors.</span>
                        </p>
                        <p><strong>6.2</strong><span className={'fw-400'}> You acknowledge and agree that the material and content contained within the Website and/or mobile application are utilised in the provision of the Services is made available by us to you for your own personal non-commercial use only. Any other use of such material and content is strictly prohibited. You agree not to (and agree not to assist or facilitate any third party to) copy, reproduce, transmit, publish, display, distribute, commercially exploit, tamper with or create derivative works of such material and content.</span>
                        </p>
                        <p><strong>6.3</strong><span className={'fw-400'}> The names, images and logos identifying us, our partners or other third parties and our/their products and services contained on the Website and or mobile application are proprietary marks and may not be reproduced or otherwise used without express permission.</span>
                        </p>

                        <p id={'remote_gaming'}><strong>7. Remote Gambling</strong></p>

                        <p><strong>Despite our best efforts </strong><span className={'fw-400'}>there may be times where our services are subject to system failures, software or hardware errors, bugs, virus, game error (meaning a change in the expected functioning of the game whatever reason) or other types of fault (&ldquo;Error&rdquo;). We will do our best to minimise errors and when they do occur, we will seek to place you in the position you were in before the Error occurred where possible to do so.</span><span
                            className={'fw-400'}> </span><span className={'fw-400'}>We regret having to impose this condition but it is essential to avoid further complications. Due to the nature of the Sportskins products it is highly unlikely that your gaming will be affected in this way.</span>
                        </p>
                        <p><strong>7.1</strong><span className={'fw-400'}> You are betting or gaming via an electronic form of communication and consequently you acknowledge that:</span>
                        </p>
                        <p><strong>7.1.1</strong><span className={'fw-400'}> you may be using a connection or equipment which is slower than the equipment used by others, and this may affect your performance in time critical events offered via the Website or mobile application; and</span>
                        </p>
                        <p><strong>7.1.2</strong><span className={'fw-400'}> you may encounter system flaws, faults, errors or service interruption caused by unexpected flaws, faults or errors in the software, hardware or networks used to provide the Services and the Website. Where such flaws, faults or errors cause a Service to be interrupted or in circumstances where it cannot be restarted from exactly the same position without any detriment to you, we will take all reasonable steps to treat you in a fair manner.</span>
                        </p>
                        <p><strong>7.2</strong><span className={'fw-400'}> The Services may be suspended temporarily and without notice in the case of system failure, maintenance or repair or for reasons reasonably beyond our control. We will not be liable to you or to any other person in the event that all or any part of the Services are discontinued, modified or changed in any way.</span>
                        </p>
                        <p><strong>7.3</strong><span className={'fw-400'}> In the event of systems or communications errors relating to, bet settlement, match results or other element of the Services, we will not be liable to you as a result of any such errors and we reserve the right to void all bets on the matches in question.</span>
                        </p>

                        <p id={'responsible_gambling'}><strong>8. Responsible Gambling</strong></p>

                        <p><strong>8.1</strong><span className={'fw-400'}> Whilst you are, and shall remain, responsible for any losses you incur as a result of your use of the Website/Mobile application and Services, we take our commitment to responsible gambling seriously. Further to this, if, in our reasonable opinion, you are at risk of developing a problem controlling your gambling, we reserve the right to suspend and/or close your Player Account.</span>
                        </p>
                        <p><strong>8.2</strong><span className={'fw-400'}> Customers who feel that they wish to take a short break from playing Sportskins games, can do so by taking a &lsquo;CGL GAMING BREAK&rsquo; for a specified period of 24 hours, 7 days or one month. This is totally at the customer&rsquo;s request. Once a &lsquo;CGL GAMING BREAK&rsquo; has been requested you will not be able to access your account for placing bets.</span>
                        </p>
                        <p><span
                            className={'fw-400'}>For more information on a &lsquo;CGL GAMING BREAK&rsquo; please contact us at email customerservices@communitygaming.co.uk with your account details and the length of break you would like applied to your account.</span>
                        </p>
                        <p><span
                            className={'fw-400'}>Please note that once the &lsquo;CGL GAMING BREAK&rsquo; period is over your account will automatically re-open.</span>
                        </p>
                        <p><span
                            className={'fw-400'}>If, however you feel that a longer period is required, or you want the period to be irreversible you should consider the Self-Exclusion option.</span>
                        </p>

                        <p id={'self_exclusion'}><strong>9. Self-Exclusion</strong></p>

                        <p><strong>9.1</strong><span className={'fw-400'}> Community Gaming Ltd offers a self-exclusion facility to help you if you feel that your gambling is out of control and you want our assistance to help stop. At your request, we will prevent you from using your account for a specific period, as determined by you, of a minimum period of 6 months.</span>
                        </p>
                        <p><span className={'fw-400'}>During this period of Self-Exclusion Community Gaming Ltd will do all we can to prevent a new account being opened. You will not receive any promotional materials directly from us and we recommend that if you are using any social media channels you take steps to ensure that you do not receive our updates or news.</span>
                        </p>
                        <p><strong>9.2</strong><span className={'fw-400'}> Accounts that have been self-excluded are unable to be reactivated under any circumstances until the expiry of the self-excluded period. At the end of the period, you may recommence betting by phoning our </span><a
                            href="https://sports.ladbrokes.com/en/mobileportal/contact"><span className={'fw-400'}>Customer Support Team</span></a><span
                            className={'fw-400'}> (email is not accepted) after a 24 hour waiting period has lapsed.</span>
                        </p>
                        <p><strong>9.3</strong><span className={'fw-400'}> CGL will disable a self-excluded players login credentials for the entire period of their requested exclusion period. If during self-exclusion a player has an entry into a competition that is still ongoing, CGL will continue to enter a pick(s) for the excluded player using the selection default mechanism(Section 5.1.7). If the player happens to win any prize money using this process CGL will contact the player by email alerting them to this fact.</span>
                        </p>
                        <p><strong>9.4</strong><span className={'fw-400'}> Our self-exclusion involves a joint commitment from us and you. We will take reasonable steps to prevent you re-opening your account or opening new accounts. However, during the period of your exclusion, you must not attempt to re-open your account or to try and open new accounts.</span>
                        </p>
                        <p><strong>9.5</strong><span className={'fw-400'}> Having implemented reasonable checks and safeguards to ensure that whilst you are self excluded you cannot open an account we cannot be held liable to you or any third party if you are able to continue to gamble on the Website/Mobile Application.</span>
                        </p>
                        <p><strong>9.6</strong><span className={'fw-400'}> To use our self-exclusion facility and in order to ensure that any accounts you have chosen to be excluded from have been closed, subject to the Self Exclusion agreement, send an email FAO Martin Cross at&nbsp; </span><span
                            className={'fw-400'}>customerservices@communitygaming.co.uk </span><span
                            className={'fw-400'}>&nbsp;with details of all the accounts, account numbers and/or usernames you have and the period that you wish the self-exclusion to apply. If a specific period is not given, we will exclude you for the minimum period of 6 months.</span>
                        </p>
                        <p><strong>9.7</strong><span className={'fw-400'}> According to your request we will either: (i) extend your self-exclusion with Community Gaming Ltd or (ii) apply your self-exclusion to all the brands run by our group that are available to users in Great Britain.&nbsp;</span>
                        </p>
                        <p><strong>9.8</strong><span
                            className={'fw-400'}> Online &ldquo;GAMSTOP&rdquo; Self-Exclusion Service</span></p>
                        <p><strong>9.8.1</strong><span className={'fw-400'}> In addition to our own internal self-exclusion facility detailed in paragraph 9.1, we are registered with the self-exclusion service provided by The National Online Self Exclusion Scheme Limited (&ldquo;GAMSTOP&rdquo;). Successfully registering with the GAMSTOP self-exclusion service will prevent you from accessing all online gambling websites and apps run by companies licensed as members of the service.&nbsp; You can find out more details about the GAMSTOP self-exclusion service by visiting </span><a
                            href="http://www.gamstop.co.uk/"><span
                            className={'fw-400'}>www.gamstop.co.uk</span></a><span
                            className={'fw-400'}>.&nbsp;</span></p>
                        <p><strong>9.8.2</strong><span className={'fw-400'}> In circumstances where you successfully register to use the GAMSTOP self-exclusion service, we will take all reasonable steps, whilst your self-exclusion is &ldquo;active&rdquo;, to prevent you from accessing your online accounts, or opening new online accounts.</span>
                        </p>
                        <p><strong>9.8.3</strong><span className={'fw-400'}> We are only able to operate self-exclusion under the GAMSTOP self-exclusion service on the basis of information you provide to GAMSTOP&nbsp; as part of your registration to use the GAMSTOP self-exclusion service. We will not be responsible for any failure on our part to spot any errors made by you or GAMSTOP when providing this information.&nbsp;&nbsp;</span>
                        </p>
                        <p><strong>9.8.4</strong><span className={'fw-400'}> You are reminded that it is in your interests to provide GAMSTOP with truthful and accurate details, consistent with those which you use/have used to set up any online accounts with us.&nbsp; It is your responsibility to keep the information provided to GAMSTOP up to date.&nbsp; We will not be responsible for you failing to keep this information up to date.&nbsp;&nbsp;</span>
                        </p>
                        <p><strong>9.8.5</strong><span className={'fw-400'}> The GAMSTOP self-exclusion service will only prevent you from accessing those online accounts that match the details you have provided to GAMSTOP as part of your self-exclusion application. Similarly, the GAMSTOP self-exclusion service will only prevent you from opening new online accounts where the personal information you provide to us matches the details you have provided to GAMSTOP as part of your self-exclusion application.&nbsp; We will not therefore be responsible for failing to prevent access to your online accounts or prohibiting the opening of new online accounts, in circumstances where inaccurate, inconsistent and/or incomplete information is provided to us and/or GAMSTOP.&nbsp;&nbsp;</span>
                        </p>
                        <p><strong>9.8.6</strong><span className={'fw-400'}> Please note that under the Terms of Use you agree with GAMSTOP, your self-exclusion will be activated as soon as possible after your application has been processed.&nbsp; Whilst GAMSTOP make every effort to do this within 24 hours, this cannot be guaranteed.&nbsp; However, once the self-exclusion has been successful and is activated on GAMSTOP&rsquo;s operating systems, the self-exclusion will take effect on our operating systems (albeit you are strongly advised to also inform us of your participation in the GAMSTOP self-exclusion service )</span>
                        </p>
                        <p><strong>9.8.7</strong><span className={'fw-400'}> To ensure that you get the full benefit of the self-exclusion facility available under the GAMSTOP self-exclusion service, you are advised to also contact the online gambling operators you use to confirm that your application to register with the GAMSTOP self-exclusion service has been successful.&nbsp; You can contact us by email at the following address: </span><span
                            className={'fw-400'}>customerservices@communitygaming.co.uk</span></p>
                        <p><strong>9.8.8</strong><span className={'fw-400'}> When contacting us to confirm that your application to register with the GAMSTOP self-exclusion service has been successful, please confirm the following information (you are reminded that the information you provide to us and GAMSTOP must be accurate, consistent and complete to ensure you can fully benefit from the GAMSTOP self-exclusion service):&nbsp;</span>
                        </p>
                        <p><strong>9.8.8.1</strong><span className={'fw-400'}> name;</span></p>
                        <p><strong>9.8.8.2</strong><span className={'fw-400'}> online account username;</span></p>
                        <p><strong>9.8.8.3</strong><span className={'fw-400'}> address; and&nbsp;</span></p>
                        <p><strong>9.8.8.4</strong><span
                            className={'fw-400'}> date of birth.&nbsp;&nbsp;&nbsp;</span></p>
                        <p><span className={'fw-400'}>You should also provide us with any postcodes you have previously lived at together with any usernames, email addresses and mobile numbers you currently use or have previously used.</span>
                        </p>
                        <p><strong>9.8.9</strong><span className={'fw-400'}> By contacting us to confirm that your application to register with the GAMSTOP self-exclusion service has been successful, we will be able to put in place measures and arrangements for the following:</span>
                        </p>
                        <p><strong>9.8.10</strong><span className={'fw-400'}> Subject to our rights under any terms and conditions you have agreed with us permitting us to withhold any funds from your online accounts, the full balance of your online accounts will be returned to you within 10 working days. Any potential returns due from bets placed before self-exclusion will be returned via our standard procedure; and</span>
                        </p>
                        <p><strong>9.8.11</strong><span className={'fw-400'}> You will not receive from us any direct online marketing materials in relation to our online products, promotions and/or services.</span>
                        </p>
                        <p><strong>9.8.12</strong><span className={'fw-400'}> After expiry of the minimum exclusion period agreed between you and GAMSTOP, you will be required to contact GAMSTOP direct to arrange for your self-exclusion to be deactivated.&nbsp; Whilst GAMSTOP make every effort to deactivate your self-exclusion as soon as possible, this cannot be guaranteed.&nbsp; Please note that we cannot process deactivation on your behalf.&nbsp; All requests to GAMSTOP for deactivation of your self-exclusion will also be subject to a 24 hour &ldquo;cooling-off&rdquo; period.&nbsp; Once your self-exclusion has been successfully deactivated, you will be able to access your online accounts/open new online accounts with us.&nbsp; In circumstances where you do not contact GAMSTOP to deactivate your self-exclusion after the period of exclusion you have agreed with GAMSTOP expires, your self-exclusion will continue for a further 7 years, after which the self-exclusion will be deactivated.&nbsp;</span>
                        </p>
                        <p><strong>9.8.13</strong><span className={'fw-400'}> You agree not to attempt to open new online accounts, log into any of your existing online accounts, or in any other way try to circumvent GAMSTOP&rsquo;s or our operating systems/mechanisms for the duration of your self-exclusion. &nbsp; The GAMSTOP self-exclusion service is intended to assist you with responsibly managing your online gambling with us.&nbsp; As confirmed in GAMSTOP&rsquo;s Terms of Use, the service is not intended to function as a replacement for willpower, is not &ldquo;foolproof&rdquo; and we cannot therefore guarantee that it will always result in you being denied access to your online accounts, or refused when applying to open new online accounts.</span>
                        </p>
                        <p><strong>9.8.14</strong><span className={'fw-400'}> Having implemented reasonable checks and safeguards to ensure that whilst you are self-excluded under the GAMSTOP self-exclusion service you cannot access an existing online account or open a new online account with us, we cannot be held liable to you or any third party if you are able to continue to enter games via our App or Website</span>
                        </p>
                        <p><strong>9.8.15</strong><span className={'fw-400'}> In addition, in no circumstances will we be liable to you or any third party if you are able to access your online accounts or open new online accounts during a period of self-exclusion under the GAMSTOP self-exclusion service for reasons outside of our reasonable control.&nbsp; This will include circumstances where the operating systems utilised by GAMSTOP fail to properly and/or instantaneously identify you and/or your online accounts as subject to self-exclusion when you attempt to use our app or website.</span>
                        </p>

                        <p id={'complaints'}><strong>10. Complaints procedure and Governing Law and Jurisdiction</strong></p>

                        <p><strong>10.1 </strong><span className={'fw-400'}>Should there be any claim or dispute arising from a past or current transaction, please </span><span
                            className={'fw-400'}>contact us customerservices@communitygaming.co.uk as soon as reasonably practical. </span><span
                            className={'fw-400'}>If, after speaking to customer services we are unable to resolve the query/complaint you may ask it to be referred to a manager or supervisor. </span><span
                            className={'fw-400'}>A Company manager/supervisor will look into the query/complaint in more detail and contact you with a resolution within 48 hours. You will be given the name and status of the person who your query/complaint has been referred to.</span>
                        </p>
                        <p><strong>10.2</strong><span className={'fw-400'}> Either party is entitled to refer the dispute/query to an arbiter, such as the Independent Betting Adjudication Service (IBAS), whose decision Community Gaming Ltd will agree to be bound by (save in respect of any manifest error). No dispute/query regarding any entrance fee placed will result in litigation, court action or objection to a bookmaker&rsquo;s licence or permit (including any remote operator&rsquo;s licence or personal licence) unless Community Gaming Ltd. fails to implement the decision given by arbitration.</span>
                        </p>
                        <p><strong>10.3</strong><span className={'fw-400'}> IBAS provides a free-of-charge service and rules on complaints about betting and gaming transactions; but it does not deal with service related problems. IBAS PO Box 62639 London EC3P 3AS Tel: 0207 347 5883 adjudication@ibas-uk.co.uk</span><a
                            href="http://www.ibas-uk.com/"><span className={'fw-400'}> </span><span
                            className={'fw-400'}>http://www.ibas-uk.com</span></a></p>
                        <p><strong>10.4</strong><span className={'fw-400'}> You will be given a copy of this complaints procedure on request, or upon making a complaint.</span>
                        </p>
                        <p><strong>10.5</strong><span className={'fw-400'}> We retain copies of all complaints referred to IBAS and provide such records to the Gambling Commission on a quarterly basis.</span>
                        </p>
                        <p><strong>10.6</strong><span className={'fw-400'}> These Terms and Conditions and any dispute or claim arising out of or in connection with it or its subject matter, whether of a contractual or non-contractual nature, shall be governed by and construed in accordance with the laws of England and Wales.</span>
                        </p>
                        <p><strong>10.7</strong><span className={'fw-400'}> By accepting these Terms and Conditions and/or depositing an entry fee and making use of (whether authorised or not) the website and/or mobile Applications provided by Community Gaming Limited, and/or by using, visiting and/or accessing any part (including, but not limited to, sub-domains, source code and/or website APIs, whether visible or not) of the Website, you irrevocably agree that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute which may arise out of or in connection with these Terms and Conditions.&nbsp;</span>
                        </p>
                        <p>&nbsp;</p>
                        <EuroTermsAndConditions />
                    </Container>
                </section>
            </div>
        );
    }

    return render();
}

export default TermsConditions;
