import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import SkinName from "./CardItems/SkinName";
import LeaguePill from "./CardItems/LeaguePill";
import GameWeek from "./CardItems/GameWeek";
import PrizeComponent from "./CardItems/PrizeComponent";
import TimeComponent from "./CardItems/TimeComponent";
import PropTypes from "prop-types";
import PickCard from "./CardItems/PickCard";
import WinningLosing from "./CardItems/WinningLosing";
import {MY_SKIN_SCHEMA} from "../../Constants/Schemas/MySkinSchema";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";

const HistoryCard = (props) => {

    const {isMobile, skin} = props;

    const [winner, setWinner] = useState(false);

    useEffect(() => {
        let isWinner = false;
        skin?.history?.map((value, index) => {
            if (value.winner) {
                isWinner = true;
            }
        });
        setWinner(isWinner);
    }, [, winner]);

    const renderMobile = () => {
        return (
            <section className={'bg-dark-blue rounded-my-skins'} role={'button'}
                /*onClick={() => {
                    navigation(`/matches/${skin.skin.id}/${skin.skin_week.id}`)
                }}*/>
                <Container fluid className={`pt-4 ${!winner && 'pb-4'} ps-4 pe-0`}>
                    <Row>
                        <Col>
                            <SkinName isMobile={isMobile} text={skin.skin.skin_name}/>
                            {
                                winner &&
                                <span>
                                    <img src={require('../../assets/images/trophy.png')} alt={"trophy icon"} height={30}
                                         className={'ps-2'}/>
                                </span>
                            }
                            <span className={'float-end pe-4 pt-2 text-skins-medium-light-grey'}>
                                Points earned:
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        {
                            skin?.history?.map((value) => (
                                <>
                                    <PickCard isMobile={isMobile} backgroundColor={skin.league.font_color}
                                              color={skin.league.background_color} number={value.pick}
                                              selectedPicks={value} history={true}
                                              inPlay={false} result={false} weekId={skin.skin_week.id}
                                              skinId={skin.skin.id}/>
                                    <hr className={'skins-hr w-100 mt-3 mb-1'}/>
                                </>
                            ))
                        }
                    </Row>
                    {
                        !winner &&
                        <Row className={`text-end`}>
                            <Col className={'me-5'}>
                                <PrizeComponent text={parseFloat(skin.skin.jackpot)} isMobile={isMobile} winner={true}/>
                            </Col>
                        </Row>
                    }
                </Container>
                {
                    winner &&
                    <div className={'w-100 bg-skins-winning rounded-my-skins-bottom ps-4 pe-4 mb-4 mt-4'}>
                        <Row className={'p-1'}>
                            <Col className={'text-start'}>
                                <div className={'text-white bebas font-30 fw-bold'}>
                                    WINNER!
                                </div>
                            </Col>
                            <Col className={'text-end'}>
                                <PrizeComponent text={parseFloat(skin.skin.jackpot)} isMobile={isMobile} winner={true}/>
                            </Col>
                        </Row>
                    </div>
                }
            </section>
        );
    }

    const renderDesktop = () => {
        return (
            <section>
                <Container className={'p-5'}>
                    <Row>
                        <Col md={5}>
                            <SkinName text={skin.skin.skin_name}/>
                            <LeaguePill
                                icon={skin.league.icon}
                                name={skin.league.name} isMobile={isMobile}/>
                            <GameWeek text={skin.skin_week.name} isMobile={isMobile}/>
                            <PrizeComponent text={parseFloat(skin.skin.jackpot)} isMobile={isMobile}/>
                            <TimeComponent datetime={skin.skin_week.game_deadline}
                                           backgroundColor={skin.league.font_color}
                                           color={skin.league.background_color} isMobile={isMobile}/>
                        </Col>
                        <Col>
                            <Row>
                                {
                                    skin.user_skin_picks.pick_1 &&
                                    <Col md={4}>
                                        <PickCard isMobile={isMobile} backgroundColor={skin.league.font_color}
                                                  color={skin.league.background_color} number={1}
                                                  selectedPicks={skin.user_skin_picks}
                                                  inPlay={false} result={false} weekId={skin.skin_week.id}
                                                  selected_team={skin.user_skin_picks.pick_1.selected_team}
                                                  match={skin.user_skin_picks.pick_1.match} skinId={skin.skin.id}/>
                                    </Col>
                                }
                                {
                                    skin.user_skin_picks.pick_2 &&
                                    <Col md={4}>
                                        <PickCard isMobile={isMobile} backgroundColor={skin.league.font_color}
                                                  color={skin.league.background_color} number={2}
                                                  selectedPicks={skin.user_skin_picks}
                                                  inPlay={false} result={false} weekId={skin.skin_week.id}
                                                  selected_team={skin.user_skin_picks.pick_2.selected_team}
                                                  match={skin.user_skin_picks.pick_2.match} skinId={skin.skin.id}/>
                                    </Col>
                                }
                                {
                                    skin.user_skin_picks.pick_3 &&
                                    <Col md={4}>
                                        <PickCard isMobile={isMobile} backgroundColor={skin.league.font_color}
                                                  color={skin.league.background_color} number={3}
                                                  selectedPicks={skin.user_skin_picks}
                                                  inPlay={false} result={false} weekId={skin.skin_week.id}
                                                  selected_team={skin.user_skin_picks.pick_3.selected_team}
                                                  match={skin.user_skin_picks.pick_3.match} skinId={skin.skin.id}/>
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <WinningLosing isMobile={isMobile} showWinning={true} winning={skin.winners}
                                               showLosing={true} showMatchesButton={true} weekId={skin.skin_week.id}
                                               losing={skin.losers} skinId={skin.id}/>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <hr className={'skins-hr w-100'}/>
            </section>
        );
    }

    const render = () => {
        if (isMobile) {
            return renderMobile();
        }

        return renderDesktop();
    }

    return render();
}

HistoryCard.propType = {
    isMobile: PropTypes.bool.isRequired,
    skin: PropTypes.shape(MY_SKIN_SCHEMA).isRequired,
}

export default HistoryCard;
