import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {MATCHES_BY_WEEK, STAGING_BASE_URL} from "../../Axios/EndPoints";
import {get} from "../../Axios/get";
import Match from "./Match";
import SmallLoader from "../SmallLoader";
import {useLocation, useSearchParams} from "react-router-dom";
import moment from "moment-timezone";

const MatchesList = (props) => {

    const {
        isMobile,
        weekId,
        currentWeek,
        setShowPicks,
        selectedPicks,
        setSelectedPicks,
        deadline,
        handlePick,
        selectionAllowed,
        setSelectionAllowed,
        selectionChanged, isEdit, pickToEdit, pickNumber, setPickNumber, setAvailablePicks
    } = props;
    const user = useSelector(state => state.Login);
    const dispatch = useDispatch();
    const state = useLocation();
    const [params] = useSearchParams();

    const [matches, setMatches] = useState([]);
    const [, setData] = useState([]);
    const [matchesLoading, setMatchesLoading] = useState(true);

    const fetchMatches = () => {
        setSelectedPicks(null);
        setMatchesLoading(true);
        let encrypted = false;
        let token = '';
        if (user.token) {
            encrypted = true;
            token = user.token;
        }
        let endpoint = MATCHES_BY_WEEK;
        endpoint = endpoint.replace('__WEEK_ID__', weekId);
        let isStaging = params.get('staging');
        get(endpoint, encrypted, token, dispatch, isStaging ? STAGING_BASE_URL : '')
            .then((response) => {
                let tempData = response?.data?.data;
                setData(tempData);
                setShowPicks(tempData?.show_which_picks);
                setSelectionAllowed(state.state ? tempData?.selection_allowed : false);
                setSelectedPicks(tempData?.selectedPicks);
                setMatches(tempData?.matches);
                let tempPickNumber = tempData?.show_which_picks.pick_1 ? 1 : (tempData?.show_which_picks.pick_2 ? 2 : (tempData?.show_which_picks.pick_3 ? 3 : 0))
                setPickNumber(tempPickNumber);
                /*let countPick1 = (((!isEdit && tempData?.show_which_picks?.pick_1) || (isEdit && tempData?.show_which_picks?.pick_1 && selectedPicks?.pick_1)) & 1 | 0);
                let countPick2 = (((!isEdit && tempData?.show_which_picks?.pick_2) || (isEdit && tempData?.show_which_picks?.pick_2 && selectedPicks?.pick_2)) & 1 | 0);
                let countPick3 = (((!isEdit && tempData?.show_which_picks?.pick_3) || (isEdit && tempData?.show_which_picks?.pick_3 && selectedPicks?.pick_3)) & 1 | 0);
                let maxPicks = countPick1 + countPick2 + countPick3;*/
                setAvailablePicks(tempPickNumber);
                setMatchesLoading(false);
            })
            .catch((error) => {
                setMatchesLoading(false);
                console.error('get_matches_error', error);
            });
    }

    useEffect(() => {
        fetchMatches();

        return (() => {
            setMatches([])
            setMatchesLoading(true);
        })

    }, [weekId]);

    useEffect(() => {
    }, [selectedPicks, selectionChanged]);

    const renderMobile = () => {
        return (
            <section aria-label={'Matches List'}>
                <SmallLoader loading={matchesLoading} className={'text-black'}/>
                <div className={'text-white mt-5 mb-5'}>
                    {
                        matches.map((value, index) => (
                            <Match isMobile={isMobile} match={value} selectedPicks={selectedPicks}
                                   deadline={deadline || moment().tz("Europe/London").format("YYYY-MM-DDTHH:mm:ss")} currentWeek={currentWeek} handlePick={handlePick}
                                   selectionAllowed={selectionAllowed} selectionChanged={selectionChanged}
                                   isEdit={isEdit} pickToEdit={pickToEdit} pickNumber={pickNumber}
                            />
                        ))
                    }
                </div>
            </section>
        );
    }

    const renderDesktop = () => {
        return (
            <section aria-label={'Matches List'}>
                <SmallLoader loading={matchesLoading}/>
                <div className={'text-white mt-5 mb-5'}>
                    {
                        matches.map((value, index) => (
                            <Match isMobile={isMobile} match={value} selectedPicks={selectedPicks}
                                   deadline={deadline || moment().tz("Europe/London").format("YYYY-MM-DDTHH:mm:ss")} currentWeek={currentWeek} handlePick={handlePick}
                                   selectionAllowed={selectionAllowed} selectionChanged={selectionChanged}
                                   isEdit={isEdit} pickToEdit={pickToEdit} pickNumber={pickNumber}
                            />
                        ))
                    }
                </div>
            </section>
        );
    }

    const render = () => {
        if (isMobile) {
            return renderMobile();
        }

        return renderDesktop();
    }

    return render();

}

MatchesList.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    selectionAllowed: PropTypes.bool.isRequired,
    skinId: PropTypes.number.isRequired,
    weekId: PropTypes.number.isRequired,
    setShowPicks: PropTypes.func.isRequired,
    setSelectedPicks: PropTypes.func.isRequired,
    setSelectionAllowed: PropTypes.func.isRequired,
    handlePick: PropTypes.func.isRequired,
    deadline: PropTypes.string.isRequired || null,
}

export default MatchesList;
