import PropTypes from "prop-types";

const RecentForm = (props) => {
    const {isMobile, recent_form} = props;

    const renderMobile = () => {
        return (
            <div className={'d-flex mb-2 justify-content-center'}>
                {
                    recent_form.map((value) => (
                        renderItem(value)
                    ))
                }
            </div>
        );
    }

    const renderItem = (item) => {
        let bg = `bg-grey`;
        if (item === 'W') {
            bg = `bg-skins-winning`;
        }
        if (item === 'L') {
            bg = `bg-skins-losing`;
        }
        return (
            <div className={`rounded-circle text-white justify-content-center d-flex align-items-center ${bg} ${isMobile ? 'font-10 ms-1' : 'font-12 ms-2'}`}
                 style={{
                     height: isMobile ? '20px' : '25px',
                     width: isMobile ? '20px' : '25px',
                 }}
            >
                {item}
            </div>
        );
    }

    const renderDesktop = () => {
        return (
            <div className={'d-flex'}>
                {
                    recent_form.map((value) => (
                        renderItem(value)
                    ))
                }
            </div>
        );
    }

    const render = () => {
        if (isMobile) {
            return renderMobile();
        }

        return renderDesktop();
    }

    return render();
}

RecentForm.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    recent_form: PropTypes.array.isRequired
}

export default RecentForm;
