import {Col, Row} from "react-bootstrap";
import SkinName from "./CardItems/SkinName";
import GameWeek from "./CardItems/GameWeek";
import PickCard from "./CardItems/PickCard";
import PrizeComponent from "./CardItems/PrizeComponent";
import Container from "react-bootstrap/Container";
import LeaguePill from "./CardItems/LeaguePill";
import WinningLosing from "./CardItems/WinningLosing";
import PropTypes from "prop-types";
import {MY_SKIN_SCHEMA} from "../../Constants/Schemas/MySkinSchema";
import PickCardStatusComponent from "./CardItems/PickCardStatusComponent";
import {useNavigate} from "react-router-dom";

const InPlay = (props) => {

    const {isMobile, skin} = props;
    const navigation = useNavigate();

    const renderMobile = () => {
        return (
            <section className={'bg-dark-blue rounded-my-skins'} role={'button'}
                     onClick={() => {
                         navigation(`/matches/${skin.skin.id}/${skin.skin_week.id}`)
                     }}
            >
                <Container fluid className={'pt-4 pb-4 ps-4 pe-0'}>
                    <Row>
                        <Col>
                            <SkinName isMobile={isMobile} text={skin.skin.skin_name}/> |
                            <GameWeek isMobile={isMobile} text={skin.skin_week.name}/>
                        </Col>
                        <Col style={{
                            marginTop: '-9px', border: 'none',
                            borderBottomLeftRadius: '15px',
                            borderTopRightRadius: '15px',
                        }}>
                            <PickCardStatusComponent isMobile={isMobile} status={'In Play'}
                                                     backgroundColor={'#18B4B3'}
                                                     color={'#0E0C1B'}/>
                        </Col>
                    </Row>
                    <Row>
                        {
                            skin.user_skin_picks.pick_1 &&
                            <PickCard isMobile={isMobile} backgroundColor={skin.league.font_color}
                                      color={skin.league.background_color} number={1}
                                      inPlay={true} result={false} skinId={skin.skin.id} weekId={skin.skin_week.id}
                                      selected_team={skin.user_skin_picks.pick_1.selected_team}
                                      match={skin.user_skin_picks.pick_1.match}/>
                        }
                        {
                            skin.user_skin_picks.pick_2 &&
                            <PickCard isMobile={isMobile} backgroundColor={skin.league.font_color}
                                      color={skin.league.background_color} number={2}
                                      inPlay={true} result={false} skinId={skin.skin.id} weekId={skin.skin_week.id}
                                      selected_team={skin.user_skin_picks.pick_2.selected_team}
                                      match={skin.user_skin_picks.pick_2.match}/>
                        }
                        {
                            skin.user_skin_picks.pick_3 &&
                            <PickCard isMobile={isMobile} backgroundColor={skin.league.font_color}
                                      color={skin.league.background_color} number={3}
                                      inPlay={true} result={false} skinId={skin.skin.id} weekId={skin.skin_week.id}
                                      selected_team={skin.user_skin_picks.pick_3.selected_team}
                                      match={skin.user_skin_picks.pick_3.match}/>
                        }
                    </Row>
                    <hr className={'skins-hr w-100'}/>
                    <Row className={'text-end'}>
                        <Col className={'me-5'}>
                            <PrizeComponent text={parseFloat(skin.skin.jackpot)} isMobile={isMobile}/>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    const renderDesktop = () => {
        return (
            <section>
                <Container className={'p-5'}>
                    <Row>
                        <Col md={5}>
                            <SkinName text={skin.skin.skin_name}/>
                            <LeaguePill
                                icon={skin.league.icon}
                                name={skin.league.name} isMobile={isMobile}/>
                            <GameWeek text={skin.skin_week.name} isMobile={isMobile}/>
                            <PrizeComponent text={parseFloat(skin.skin.jackpot)} isMobile={isMobile}/>
                            <div className={'bg-skins-teal p-3 rounded-4 w-fit ps-5 pe-5 mt-4 text-center'}>
                                <div className={'roboto text-uppercase font-20 fw-bold text-center'}>
                                    In Play
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <Row>
                                {
                                    skin.user_skin_picks.pick_1 &&
                                    <Col md={4}>
                                        <PickCard isMobile={isMobile} backgroundColor={skin.league.font_color}
                                                  color={skin.league.background_color} number={1}
                                                  inPlay={true} result={false} skinId={skin.skin.id}
                                                  weekId={skin.skin_week.id}
                                                  selected_team={skin.user_skin_picks.pick_1.selected_team}
                                                  match={skin.user_skin_picks.pick_1.match}/>
                                    </Col>
                                }
                                {
                                    skin.user_skin_picks.pick_2 &&
                                    <Col md={4}>
                                        <PickCard isMobile={isMobile} backgroundColor={skin.league.font_color}
                                                  color={skin.league.background_color} number={2}
                                                  inPlay={true} result={false} skinId={skin.skin.id}
                                                  weekId={skin.skin_week.id}
                                                  selected_team={skin.user_skin_picks.pick_2.selected_team}
                                                  match={skin.user_skin_picks.pick_2.match}/>
                                    </Col>
                                }
                                {
                                    skin.user_skin_picks.pick_3 &&
                                    <Col md={4}>
                                        <PickCard isMobile={isMobile} backgroundColor={skin.league.font_color}
                                                  color={skin.league.background_color} number={3}
                                                  inPlay={true} result={false} skinId={skin.skin.id}
                                                  weekId={skin.skin_week.id}
                                                  selected_team={skin.user_skin_picks.pick_3.selected_team}
                                                  match={skin.user_skin_picks.pick_3.match}/>
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <WinningLosing isMobile={isMobile} showWinning={true} winning={skin.winners}
                                               showLosing={true} showMatchesButton={true} weekId={skin.skin_week.id}
                                               losing={skin.losers} skinId={skin.skin.id}/>
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <hr className={'skins-hr w-100'}/>
            </section>
        );
    }

    const render = () => {
        if (isMobile) {
            return renderMobile();
        }

        return renderDesktop();
    }

    return render();
}

InPlay.propType = {
    isMobile: PropTypes.bool.isRequired,
    skin: PropTypes.shape(MY_SKIN_SCHEMA).isRequired,
}

export default InPlay;
