import PropTypes from "prop-types";
import {getNumberWithOrdinal} from "../../CustomFunctions/getNumberWithOrdinal";
import {Col, Row} from "react-bootstrap";
import {SELECTED_PICKS_SCHEMA} from "../../Constants/Schemas/MySkinSchema";
import NumberOfPicks from "./NumberOfPicks";
import {useEffect} from "react";

const TeamCard = (props) => {
    const {
        isMobile,
        team,
        selectedPicks,
        win_team_id,
        isDraw,
        match,
        isDeadlineReached,
        currentWeek,
        handlePick,
        selectionAllowed,
        selectionChanged, isEdit, pickToEdit, pickNumber
    } = props;

    useEffect(() => {
    }, [selectedPicks, selectionChanged, pickNumber]);

    const renderMobile = (isUsed, isSelected, isWin, isLost) => {
        return (
            <div
                className={`${isSelected ? (isWin ? 'bg-skins-winning text-white' : (isLost ? 'bg-skins-losing text-white' : (isDeadlineReached ? 'bg-black text-white' : 'bg-team-card-grey text-black'))) : `bg-team-card-grey ${isUsed ? 'opacity-50' : ''} text-black`}
                rounded-4 p-2 m-2 w-100 text-center justify-content-center`}>
                <Row>
                    <Col
                        className={'text-uppercase roboto font-10 text-start align-items-start d-flex justify-content-start'}>
                        {getNumberWithOrdinal(team.position)}
                    </Col>
                    <Col className={'justify-content-end text-end'} style={{
                        marginTop: '-8px', border: 'none',
                        borderBottomLeftRadius: '15px',
                        borderTopRightRadius: '15px',
                    }}>
                        {renderUsed(isUsed, isWin, isLost, isSelected, team)}
                        {renderSelectedPickNumber(isSelected, isWin, isLost)}
                    </Col>
                </Row>
                <div className={'text-capitalize roboto text-center align-items-center justify-content-center'}>
                    <img src={team.icon} alt={`${team.name} icon`} height={24}/>
                    <div className={'roboto font-15 mt-2'}>
                        {team.alt_name}
                    </div>
                </div>
                {isSelected && isDeadlineReached &&
                    <NumberOfPicks isMobile={isMobile} team={team} match={match} showInTeamCard={isSelected}/>}
            </div>
        );
    }

    const renderUsed = (isUsed, isWin, isLost, isSelected, team) => {
        if (isEdit && currentWeek && !team[`is_used_pick_${pickToEdit.number}`]) {
            isUsed = false;
        }
        if (!isEdit && selectionAllowed && currentWeek && !team[`is_used_pick_${pickNumber}`]) {
            isUsed = false;
        }
        if ((isUsed && !isSelected && (!isWin && !isLost)) || (!isSelected && isUsed)) {
            return (
                <div className={'justify-content-center d-inline-flex border-0'} style={{
                    backgroundColor: '#dc1422',
                    color: '#fff',
                    borderBottomLeftRadius: '15px',
                    borderTopRightRadius: '15px',
                    position: 'relative',
                    left: 'auto',
                    right: '-7px',
                    marginTop: '0',
                    padding: '1px 15px',
                    opacity: '1',
                    top: isMobile ? '-6px' : '0',
                    fontSize: isMobile ? '9px' : '14px'
                }}>
                    Used
                </div>
            );
        }
    }

    const renderSelectedPickNumber = (isSelected, isWin, isLost) => {
        if (isSelected) {
            return (
                <div className={'justify-content-center d-inline-flex'}>
                    {selectedPicks?.pick_1?.team_id === team.goalserve_id &&
                        <span
                            className={`${isDeadlineReached && (!isWin && !isLost) ? 'bg-skins-teal' : 'bg-black'} rounded-circle text-white font-10 text-center`}
                            style={{height: '15px', width: '15px'}}>1</span>
                    }
                    {selectedPicks?.pick_2?.team_id === team.goalserve_id &&
                        <span
                            className={`${isDeadlineReached && (!isWin && !isLost) ? 'bg-skins-teal' : 'bg-black'} rounded-circle text-white font-10 text-center ms-1`}
                            style={{height: '15px', width: '15px'}}>2</span>
                    }
                    {selectedPicks?.pick_3?.team_id === team.goalserve_id &&
                        <span
                            className={`${isDeadlineReached && (!isWin && !isLost) ? 'bg-skins-teal' : 'bg-black'} rounded-circle text-white font-10 text-center ms-1`}
                            style={{height: '15px', width: '15px'}}>3</span>
                    }
                </div>
            );
        }
    }

    const renderDesktop = (isUsed, isSelected, isWin, isLost) => {
        return (
            <div
                className={`${isSelected ? (isWin ? 'bg-skins-winning text-white' : (isLost ? 'bg-skins-losing text-white' : (isDeadlineReached ? 'bg-black text-white' : 'bg-team-card-grey text-black'))) : `bg-team-card-grey ${isUsed ? 'opacity-50' : ''} text-black`} rounded-4 p-2 m-2`}>
                <Row>
                    <Col
                        className={'text-uppercase roboto font-12 text-start align-items-start d-flex justify-content-start'}>
                        {getNumberWithOrdinal(team.position)}
                    </Col>
                    <Col className={'justify-content-end text-end'} style={{
                        marginTop: '-8px', border: 'none',
                        borderBottomLeftRadius: '15px',
                        borderTopRightRadius: '15px',
                    }}>
                        {renderUsed(isUsed, isWin, isLost, isSelected, team)}
                        {renderSelectedPickNumber(isSelected, isWin, isLost)}
                    </Col>
                </Row>
                <div className={'text-capitalize roboto text-center align-items-center justify-content-center'}>
                    <img src={team.icon} alt={`${team.name} icon`} height={42}/>
                    <div className={'roboto font-15 mt-2'}>
                        {team.alt_name}
                    </div>
                </div>
            </div>
        );
    }

    const renderMain = (isUsed, isSelected, isWin, isLost) => {
        if (isMobile) {
            return renderMobile(isUsed, isSelected, isWin, isLost);
        }

        return renderDesktop(isUsed, isSelected, isWin, isLost);
    }

    const render = () => {
        let isUsed = team.is_used_pick_1 || team.is_used_pick_2 || team.is_used_pick_3;
        let isSelected = false;
        if ([selectedPicks?.pick_1?.team_id, selectedPicks?.pick_2?.team_id, selectedPicks?.pick_3?.team_id].includes(team.goalserve_id)) {
            isSelected = true;
        }
        if (isEdit && currentWeek && !team[`is_used_pick_${pickToEdit.number}`]) {
            isUsed = false;
        }
        if (!isEdit && selectionAllowed && currentWeek && !team[`is_used_pick_${pickNumber}`]) {
            isUsed = false;
        }
        let isWin = false;
        let isLost = false;
        if (win_team_id > 0 && win_team_id === parseInt(team.goalserve_id)) {
            isWin = true;
        }
        if ((win_team_id > 0 && win_team_id !== parseInt(team.goalserve_id)) || isDraw) {
            isLost = true;
        }
        return (
            <div role={'button'} className={'w-100'} onClick={() => {
                if (currentWeek && !isUsed && selectionAllowed) {
                    let selectedTeam = {
                        team_id: team.goalserve_id,
                        match_id: match.goalserve_id,
                        team: team,
                    };
                    handlePick(selectedTeam);
                }
            }}>
                {renderMain(isUsed, isSelected, isWin, isLost)}
            </div>
        );
    }

    return render();
}

TeamCard.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    currentWeek: PropTypes.bool.isRequired,
    selectionAllowed: PropTypes.bool.isRequired,
    handlePick: PropTypes.func.isRequired,
    isDraw: PropTypes.bool.isRequired,
    isDeadlineReached: PropTypes.bool.isRequired,
    team: PropTypes.shape({
        id: PropTypes.string.isRequired,
        goalserve_id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        alt_name: PropTypes.string.isRequired,
        abbreviation: PropTypes.string.isRequired,
        icon: PropTypes.string.isRequired,
        country: PropTypes.string.isRequired,
        recent_form: PropTypes.array.isRequired,
        position: PropTypes.string.isRequired,
        number_of_picks: PropTypes.number.isRequired,
        is_used_pick_1: PropTypes.bool.isRequired,
        is_used_pick_2: PropTypes.bool.isRequired,
        is_used_pick_3: PropTypes.bool.isRequired
    }).isRequired,
    win_team_id: PropTypes.number,
    selectedPicks: SELECTED_PICKS_SCHEMA,
    match: PropTypes.object.isRequired,
}

export default TeamCard;
