import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {Parallax} from "react-parallax";
import {useEffect, useRef, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper/modules";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {HomeSection4Images} from "../assets/data/HomeSection4Images";
import HowToPlayComponent from "../Components/HowToPlayComponent";
import SkinsFooter from "../Components/SkinsFooter";
import {Helmet} from "react-helmet";
import CampaignModal from "../Components/CampaignModal";

const Home = (props) => {
    const {isMobile} = props;
    const sliderRef = useRef(null);

    // const [showCampaignModal, setShowCampaignModal] = useState(true);

    /*const handlePrev = useCallback(() => {
        // if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
    }, []);

    const handleNext = useCallback(() => {
        // if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);*/

    useEffect(() => {
    }, []);

    const render = () => {
        return (
            <div>
                <Helmet>
                    <title>Sportskins - Home</title>
                    <meta name="description" content="Sportskins- Home"/>
                </Helmet>
                {renderSection1()}
                {isMobile ? renderSection2() : renderSection3()}
                {isMobile ? renderSection3() : renderSection2()}
                {renderSection4()}
                <SkinsFooter isMobile={isMobile}/>
            </div>
        );
    };

    const renderSection1 = () => {
        return (
            <section className={`home-section-1-bg p-xxl-5 p-xl-5 p-lg-5 p-md-5 p-sm-1 mt-0`}>
                <Container fluid={true}>
                    <Row>
                        <Col xxl={3} xl={3} lg={3} md={3} sm={false} className={'d-none d-md-block'}>
                            <img className={'img-fluid'}
                                 src={require('../assets/images/wp-content/uploads/2023/03/iPHONE.png')}
                                 alt={'Home iPhone'}/>
                        </Col>
                        <Col xxl={7} xl={7} lg={7} md={7} sm={12}>
                            <h2 className={`mb-0 mt-5 text-skins-red syne text-center font-16 fw-bold`}>
                                WELCOME TO
                            </h2>
                            <h1 className={`dokyo mt-0 text-center fw-bold heading-home-skins`}>
                                <span className={'text-white heading-home-skins'}>
                                    SPORT
                                </span>
                                <span className={'text-skins-teal heading-home-skins'}>
                                    SKINS
                                </span>
                            </h1>
                            <div className={'font-18 cabin text-white text-center mt-5'}
                                 style={{letterSpacing: '1.2px', lineHeight: '1.6em'}}>
                                The home of sports low stake, high return competitions that pit your sporting knowledge
                                and selection skills against your fellow community members
                            </div>
                            <div className={'text-center mt-5 pt-5'}>
                                <Row className={'text-center justify-content-center d-flex'}>
                                    <Col className={'mt-2 text-sm-center text-md-end'}>
                                        <a href={'https://play.google.com/store/apps/details?id=uk.sportskins'}
                                           target={'_blank'} rel={'noreferrer'}>
                                            <img
                                                src={require('../assets/images/home/google_play.png')}
                                                alt={'Home Google Play'}/>
                                        </a>
                                    </Col>
                                    <Col className={'mt-2 text-sm-center text-md-start'}>
                                        <a href={'https://apps.apple.com/gb/app/sportskins-football-betting/id1668307911'}
                                           target={'_blank'} rel={'noreferrer'}>
                                            <img
                                                src={require('../assets/images/home/app_store.png')}
                                                alt={'Home App Store'}/>
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                            <div className={'text-center mt-5 pt-5'}>
                                <Row className={'text-center justify-content-center d-flex'}>
                                    <Col className={'mt-2 text-sm-center text-md-center'}>
                                        <a href={'https://www.begambleaware.org/'} target={'_blank'} rel={'noreferrer'}>
                                            <img
                                                src={require('../assets/images/wp-content/uploads/2021/11/Gamble-aware-Logo-footer.png')}
                                                className={'img-fluid'}
                                                alt="be_gamble_aware_home_image"/>
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col className={'text-center text-white mt-5 pt-5 d-none d-md-block'} md={2}>
                            <ul className="list-unstyled pt-5">
                                <li className="pt-1 pb-5">
                                    <a href="https://facebook.com">
                                        <FontAwesomeIcon icon={faFacebookF} size={'2x'} className={'text-white'}/>
                                    </a>
                                </li>
                                <li className="pt-1 pb-5">
                                    <a href="https://instagram.com">
                                        <FontAwesomeIcon icon={faInstagram} size={'2x'} className={'text-white'}/>
                                    </a>
                                </li>
                                <li className="pt-1 pb-5">
                                    <a href="https://twitter.com">
                                        <FontAwesomeIcon icon={faTwitter} size={'2x'} className={'text-white'}/>
                                    </a>
                                </li>
                                <li className="pt-1 pb-5">
                                    <a href="https://youtube.com">
                                        <FontAwesomeIcon icon={faYoutube} size={'2x'} className={'text-white'}/>
                                    </a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    const renderSection2 = () => {
        return (
            <section className={`home-section-2-bg p-xxl-5 p-xl-5 p-lg-5 p-md-5 p-sm-1 mt-0 mb-4`}>
                <Container>
                    <Row>
                        {
                            isMobile &&
                            <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                                <Parallax
                                    bgImage={require('../assets/images/wp-content/uploads/elementor/thumbs/iphone-double-ss@3x-q3sr5jl8zsw3vha5u2jusegflh0bu2xoehbbvt2mhq.png')}>
                                    <div style={{height: '570px'}}>
                                    </div>
                                </Parallax>
                            </Col>
                        }
                        <Col xxl={6} xl={6} lg={6} md={6} sm={12}>
                            <div className={`mb-0 mt-5 text-skins-teal cabin text-start font-48 fw-bold`}>
                                The Sportskins Brand
                            </div>
                            <div className={'font-17 cabin text-white text-start mt-5'}>
                                Sportskins is the home of ‘Premskins’ the ultimate Premier League Last Player Standing
                                game and ‘Champskins’ its sister competition based on the English Championship.
                            </div>
                            <div className={'font-17 cabin text-white text-start mt-5'}>
                                We aim to continually innovate, introduce new competitions and give value added content
                                to enhance the enjoyment of the community on a variety of sports under the ‘Sportskins’
                                umbrella.
                            </div>
                            <div className={`mb-0 mt-5 text-skins-red cabin text-start font-48 fw-bold`}>
                                Why Join SportSkins?
                            </div>
                            <div className={'font-17 cabin text-white text-start mt-5'}>
                                At Community Gaming Limited (Sportskins is a trading name of Community Gaming Limited )
                                we are committed to providing the opportunity for any player to have the chance to win
                                large cash prizes for a small entry fee.
                            </div>
                            <div className={'font-17 cabin text-white text-start mt-5'}>
                                Our ethos is to create a fun, entertaining and informative platform that a member can
                                engage with and become part of a larger like-minded community.
                            </div>
                            <div className={'font-17 cabin text-white text-start mt-5'}>
                                So sign up, it’s free and download the App. Tell your friends and why not join our
                                online community to discuss your predictions or to generally talk about the sporting
                                events of the day.
                            </div>
                            <div className={'font-17 cabin text-white text-start mt-5'}>
                                Can you be the person to survive and take home the ultimate prize?
                            </div>
                            <div className={'font-17 cabin text-white text-start mt-5'}>
                                GOOD LUCK
                            </div>
                            <div className={'font-17 cabin text-white text-start mt-5'}>
                                At CGL we take our responsibilities to gambling and its effects extremely seriously
                            </div>
                            <div className={'font-17 cabin text-white text-start mt-5'}>
                                <a href={'/responsible-gambling'} className={'text-skins-red text-decoration-none'}>
                                    Click here to view our responsible gambling page
                                </a>
                            </div>
                        </Col>
                        {
                            !isMobile &&
                            <Col xxl={6} xl={6} lg={6} md={6} sm={false} className={'d-none d-md-block'}>
                                <Parallax
                                    bgImage={require('../assets/images/wp-content/uploads/elementor/thumbs/iphone-double-ss@3x-q3sr5jl8zsw3vha5u2jusegflh0bu2xoehbbvt2mhq.png')}>
                                    <div style={{height: '1200px'}}>
                                    </div>
                                </Parallax>
                            </Col>
                        }
                    </Row>
                </Container>
            </section>
        );
    }

    const renderSection3 = () => {
        return (
            <section className={`bg-white p-xxl-5 p-xl-5 p-lg-5 p-md-5 p-sm-1 mt-0`}>
                <HowToPlayComponent isMobile={isMobile}/>
            </section>
        );
    }

    const renderSection4 = () => {

        return (
            <section className={''}>
                <div className={`${isMobile ? 'bg-dark-blue' : 'bg-skins-teal'} rounded-top-home-section-4 pb-4`}>
                    <div className={'text-center pt-5'}>
                        <h2 className={'text-white cabin font-48'}>Features</h2>
                    </div>
                    <div className={'text-center mb-5'}>
                        <p className={'text-white cabin font-20'}>
                            Check out our app, where you can view your account, check out the current prize pot, look up
                            our game rules, and much more.
                        </p>
                    </div>
                    <Swiper
                        ref={sliderRef}
                        slidesPerView={isMobile ? 1.5 : 5}
                        centeredSlides
                        pagination={{
                            clickable: true,
                            bulletClass: 'swiper-pagination-bullet bg-white mt-5',
                        }}
                        navigation={true}
                        loop={true}
                        modules={[Navigation, Pagination]}
                        className="mySwiper mt-5"
                    >
                        {
                            HomeSection4Images?.map((image, index) => {
                                return (
                                    <SwiperSlide
                                        key={`home_features_images_${index}`}
                                        className={'text-white'}>
                                        <img src={image.src} alt={`${image.alt}`}/>
                                    </SwiperSlide>
                                );
                            })
                        }
                    </Swiper>
                    {/*<div className="icon" onClick={handlePrev}>
                    <div className="prev-arrow"/>
                </div>
                <div className="icon" onClick={handleNext}>
                    <div className="next-arrow"/>
                </div>*/}
                </div>
            </section>
        );
    }

    return render();
}


Home.propTypes = {};
export default Home;
