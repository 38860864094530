import {Button, Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import SkinName from "./CardItems/SkinName";
import LeaguePill from "./CardItems/LeaguePill";
import GameWeek from "./CardItems/GameWeek";
import PrizeComponent from "./CardItems/PrizeComponent";
import TimeComponent from "./CardItems/TimeComponent";
import SmallLoader from "../SmallLoader";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {MY_SKIN_SCHEMA} from "../../Constants/Schemas/MySkinSchema";
import {useNavigate} from "react-router-dom";

const WeekOpened = (props) => {

    const {isMobile, skin} = props;
    const navigation = useNavigate();

    const [loading,] = useState(false);

    useEffect(() => {
    }, [isMobile]);

    const renderMobile = () => {
        return (
            <section className={'bg-dark-blue rounded-my-skins'}>
                <Container fluid className={'p-4'}>
                    <Row>
                        <Col>
                            <SkinName isMobile={isMobile} text={skin.skin.skin_name}/> | <GameWeek isMobile={isMobile}
                                                                                                   text={skin.skin_week.name}/>
                        </Col>
                        <Col style={{
                            marginTop: '-9px', border: 'none',
                            borderBottomLeftRadius: '15px',
                            borderTopRightRadius: '15px',
                        }}>
                            <TimeComponent isMobile={isMobile} datetime={skin.skin_week.game_deadline}
                                           backgroundColor={'#ffdd07'}
                                           color={'#000000'}/>
                        </Col>
                    </Row>
                    <Row className={'mt-4 mb-4'}>
                        <div className={'col-5'}>
                            <div className={'text-skins-yellow roboto fw-bold font-27 text-uppercase'}>
                                {skin.skin_week.name} Open
                            </div>
                            <div className={'col-6 offset-1'}>
                                <Button
                                    onClick={() => navigation(`/matches/${skin.skin.id}/${skin.skin_week.id}`, {state: {isWeekOpened: true}})}
                                    className={'mt-2 mb-3 btn-skins-red rounded-pill roboto fw-bold font-24 text-uppercase'}>
                                    <SmallLoader loading={loading}/>
                                    {
                                        !loading &&
                                        (
                                            <>
                                                Pick
                                            </>
                                        )
                                    }
                                </Button>
                            </div>
                        </div>
                        <div className={'col-5 text-end align-self-center'}>
                            <div className={'text-white roboto fw-normal font-20 text-end'}>
                                    <span className={'text-white roboto font-17 text-end'}>
                                        Congratulations, make {skin.picks_available} {skin.picks_available === 1 ? 'pick' : 'picks'}
                                    </span>
                            </div>
                        </div>
                        <div className={'col-2 align-self-center'}>
                            <img src={require('../../assets/images/trophy.png')} alt={"trophy icon"}
                                 className={'img-fluid'}/>
                        </div>
                    </Row>
                    <Row>
                        <Col className={'col-6 text-start'}>
                                <span className={'text-white bebas fw-bold font-25'}>
                                    {Intl.NumberFormat("en-US", {maximumFractionDigits: 0}).format(skin.remaining)}
                                </span>
                            {" "}
                            <span className={'text-white roboto font-12'}>
                                    Remaining
                                </span>
                        </Col>
                        <Col className={'col-6 text-end'}>
                            <PrizeComponent text={skin.skin.jackpot} isMobile={isMobile}/>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
    const renderDesktop = () => {
        return (
            <section>
                <Container className={'p-5'}>
                    <Row>
                        <Col md={5}>
                            <SkinName text={skin.skin.skin_name}/>
                            <LeaguePill
                                icon={skin.league.icon}
                                name={skin.league.name} isMobile={isMobile}/>
                            <GameWeek text={skin.skin_week.name} isMobile={isMobile}/>
                            <PrizeComponent text={skin.skin.jackpot} isMobile={isMobile}/>
                            <TimeComponent datetime={skin.skin_week.game_deadline} backgroundColor={'#ffdd07'}
                                           color={'#000000'} isMobile={isMobile}/>
                        </Col>
                        <Col>
                            <div className={'text-skins-yellow roboto fw-bold font-43 text-uppercase'}>
                                {skin.skin_week.name} Open
                            </div>
                            <div className={'text-white roboto fw-normal font-20 mt-4 mb-3'}>
                                <img src={require('../../assets/images/trophy.png')} alt={"trophy icon"} height={43}
                                     color={'#ffdd07'}/>
                                <span className={'text-white roboto font-20 ms-5'}>
                                    Congratulations, make {skin.picks_available} {skin.picks_available === 1 ? 'pick' : 'picks'}
                                </span>
                            </div>
                            <div className={'d-grid col-8'}>
                                <Button
                                    onClick={() => navigation(`/matches/${skin.skin.id}/${skin.skin_week.id}`, {state: {isWeekOpened: true}})}
                                    className={'btn text-white mt-3 mb-3 btn-skins-red rounded-pill roboto fw-bold font-24 text-uppercase pt-4 pb-4'}
                                    size={'lg'}>
                                    <SmallLoader loading={loading}/>
                                    {
                                        !loading &&
                                        (
                                            <>
                                                Pick
                                            </>
                                        )
                                    }
                                </Button>
                            </div>
                            <div className={'d-flex align-items-center mt-4 ms-2'}>
                                <span className={'text-white roboto fw-bold font-27'}>
                                    {Intl.NumberFormat("en-US", {maximumFractionDigits: 0}).format(skin.remaining)}
                                </span>
                                <span className={'text-white roboto font-20 ms-3'}>
                                    Remaining
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <hr className={'skins-hr w-100'}/>
            </section>
        );
    }

    const render = () => {
        if (isMobile) {
            return renderMobile();
        }

        return renderDesktop();
    }

    return render();
}

WeekOpened.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    skin: PropTypes.shape(MY_SKIN_SCHEMA).isRequired,
};

export default WeekOpened;
