import {Spinner} from "react-bootstrap";
import PropTypes from "prop-types";

const SmallLoader = (props) => {

    const {loading, className} = props;
    const render = () => {
        return (
            <div className={'d-flex align-items-center justify-content-center mt-3 mb-3'}>
                <Spinner animation="border" role="status" className={className}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return loading && render();
}

SmallLoader.propTypes = {
    loading: PropTypes.bool.isRequired,
    className: PropTypes.string.isRequired
}

SmallLoader.defaultProps = {
    className: 'text-white'
}

export default SmallLoader;
