import {Alert, Button, Col, Form, Modal, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {post} from "../Axios/post";
import {FORGOT_PASSWORD} from "../Axios/EndPoints";
import * as yup from 'yup';
import {Formik} from "formik";
import SmallLoader from "./SmallLoader";

const ForgotPasswordModal = (props) => {

    const {showModal, setShowModal} = props;

    const [loading, setLoading] = useState(false);
    const [apiErrors, setApiErrors] = useState('');

    const schema = yup.object().shape({
        email: yup.string().email().required(),
    });

    const handleForgotPassword = (values) => {
        setLoading(true);
        post(FORGOT_PASSWORD, values)
            .then((response) => {
                if (response?.data?.success) {
                    values.email = '';
                }
                // setShowModal(false);
            })
            .catch((error) => {
                console.error('contact submit error', error.response);
                setApiErrors(error?.response?.data?.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {

    }, [showModal]);

    const renderForm = () => {
        return (
            <Formik
                validationSchema={schema}
                onSubmit={handleForgotPassword}
                initialValues={{
                    email: '',
                }}
                enableReinitialize={true}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({handleSubmit, handleChange, values, touched, errors}) => (
                    <Form className={'login-form p-2'} noValidate onSubmit={handleSubmit}>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        id={'email'}
                                        type="email" placeholder="Email *"
                                        value={values.email}
                                        onChange={handleChange}
                                        isValid={touched.email && !errors.email}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback tooltip>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid" tooltip>
                                        {errors.email + ''}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className={'d-grid'}>
                                <Button className={'btn-skins-red'} type={'submit'} size={'lg'}>
                                    <SmallLoader loading={loading}/>
                                    {
                                        !loading &&
                                        (
                                            <>
                                                Send Email
                                            </>
                                        )
                                    }
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        );
    }

    const renderErrors = () => {
        if (apiErrors !== '') {
            return (
                <Alert variant="danger" onClose={() => setApiErrors('')} dismissible>
                    <p className={'cabin fw-bold font-18'}>
                        {apiErrors}
                    </p>
                </Alert>
            );
        }
    }

    const render = () => {
        return (
            <section>
                <Modal
                    show={showModal}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => {
                        setShowModal(false)
                    }}
                >
                    <Modal.Body className={'text-center'} style={{backgroundColor: '#f3f1f7'}}>
                        {renderForm()}
                        {renderErrors()}
                    </Modal.Body>
                </Modal>
            </section>
        );
    }

    return render();
}

ForgotPasswordModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
}

export default ForgotPasswordModal;
