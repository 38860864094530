import {Row, Tab, Tabs} from "react-bootstrap";
import WeekOpened from "../Components/MySkinCards/WeekOpened";
import InPlay from "../Components/MySkinCards/InPlay";
import Result from "../Components/MySkinCards/Result";
import MySkin from "../Components/MySkinCards/MySkin";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import LoginModal from "../Components/LoginModal";
import PropTypes from "prop-types";
import {get} from "../Axios/get";
import {MY_SKINS, STAGING_BASE_URL} from "../Axios/EndPoints";
import {MATCH_STATES} from "../Constants/Match";
import SmallLoader from "../Components/SmallLoader";
import moment from "moment-timezone";
import BottomFooter from "../Components/BottomFooter";
import HistoryCard from "../Components/MySkinCards/HistoryCard";
import {useSearchParams} from "react-router-dom";
import {Helmet} from "react-helmet";

const MySkins = (props) => {

    const {isMobile} = props;
    const isLogin = useSelector(state => state.Login.isLogin);
    const user = useSelector(state => state.Login);
    const dispatch = useDispatch();
    const [params] = useSearchParams();

    const [showLoginModal, setShowLoginModal] = useState(false);
    const [loading, setLoading] = useState(true);
    // const [skinStatus,] = useState('InProgress');
    const [skinsList, setSkinsList] = useState([]);

    const fetchMySkins = (skinStatus) => {
        setLoading(true);
        let encrypted = false;
        let token = '';
        if (user.token) {
            encrypted = true;
            token = user.token;
        }
        let endpoint = MY_SKINS + skinStatus;
        let isStaging = parseInt(params.get('staging'));
        get(endpoint, encrypted, token, dispatch, isStaging ? STAGING_BASE_URL : '')
            .then((response) => {
                setSkinsList(response?.data?.data);
            })
            .catch((error) => {
                console.error('my_skins_error', error);
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (!isLogin) {
            setShowLoginModal(true);
        } else {
            fetchMySkins('InProgress');
        }
    }, [isLogin]);

    const renderDesktop = () => {
        return (
            <>
                <Row>
                    <div className={'text-center fw-bold font-60 h1 text-white gibson mt-5 text-uppercase'}>
                        <span className={''}>My</span> {' '}
                        <span className={'text-skins-teal'}>Skins</span>
                    </div>
                </Row>
                <Row>
                    <div className={'text-center fw-bold font-24 text-white cabin mb-5 text-uppercase'}>
                        Welcome Back!
                    </div>
                </Row>
                <hr className={'skins-hr w-100'}/>
                {renderList()}
                <BottomFooter/>
            </>
        );
    }

    const handleTabChange = (el) => {
        setSkinsList([]);
        setLoading(true);
        let skinStatus = el.id.replace('my-skins-mobile-tabpane-', '');
        fetchMySkins(skinStatus);
    }

    const renderMobile = () => {
        return (
            <Tabs
                defaultActiveKey="InProgress"
                id="my-skins-mobile"
                className="mb-3 justify-content-around"
                fill
            >
                <Tab eventKey="InProgress" title={<span className={'nav-span ps-3 pe-3 roboto font-15 fw-500 float-end me-3'}>Open</span>}
                     onEnter={handleTabChange}>
                    {renderList()}
                </Tab>
                <Tab eventKey="Closed" title={<span className={'nav-span ps-3 pe-3 roboto font-15 fw-500 float-start ms-3'}>History</span>}
                     onEnter={handleTabChange}>
                    {renderList(true)}
                </Tab>
            </Tabs>
        );
    }

    const renderList = (isHistory) => {
        return (
            <div className={isMobile ? `w-100 bg-skins-white rounded-my-skins-top pt-5 pb-3 ${skinsList.length > 1 ? '' : 'vh-100 h-100'}` : 'container'}>
                <SmallLoader loading={loading} className={isMobile ? 'text-black' : 'text-white'}/>
                {
                    skinsList?.map((value, index) => {
                        const upcomingMatchesOne = MATCH_STATES.UPCOMING.includes(
                            value?.user_skin_picks?.pick_1?.match?.state);
                        const upcomingMatchesTwo = MATCH_STATES.UPCOMING.includes(
                            value?.user_skin_picks?.pick_2?.match?.state);
                        const upcomingMatchesThree = MATCH_STATES.UPCOMING.includes(
                            value?.user_skin_picks?.pick_3?.match?.state);

                        const liveMatchOne = MATCH_STATES.IN_PLAY.includes(
                            value?.user_skin_picks?.pick_1?.match?.state);

                        const liveMatchTwo = MATCH_STATES.IN_PLAY.includes(
                            value?.user_skin_picks?.pick_2?.match?.state);

                        const liveMatchThree = MATCH_STATES.IN_PLAY.includes(
                            value?.user_skin_picks?.pick_3?.match?.state);

                        const matchOneFinished = value?.user_skin_picks?.pick_1 ? MATCH_STATES.FINISHED.includes(
                            value?.user_skin_picks?.pick_1?.match?.state) : true;
                        const matchTwoFinished = value?.user_skin_picks?.pick_2 ? MATCH_STATES.FINISHED.includes(
                            value?.user_skin_picks?.pick_2?.match?.state) : true;
                        const matchThreeFinished = value?.user_skin_picks?.pick_3 ? MATCH_STATES.FINISHED.includes(
                            value?.user_skin_picks?.pick_3?.match?.state) : true;

                        const nowTime = moment().tz("Europe/London").format("YYYY-MM-DDTHH:mm:ss");
                        // const nowTime = moment('2023-09-23 14:05:00').tz("Europe/London").format("YYYY-MM-DDTHH:mm:ss");
                        const deadlineTime = moment(value.skin_week.game_deadline).format("YYYY-MM-DDTHH:mm:ss");
                        let isDeadlineReached = nowTime >= deadlineTime;

                        const isLive = liveMatchOne || liveMatchTwo || liveMatchThree || isDeadlineReached;
                        const isFinished = matchOneFinished && matchTwoFinished && matchThreeFinished;
                        const isUpcoming = (upcomingMatchesOne || upcomingMatchesTwo || upcomingMatchesThree) && !isDeadlineReached;
                        if (isHistory) {
                            return (
                                <div className={'m-2'} key={`skins_${index}`}>
                                    <HistoryCard isMobile={isMobile} skin={value}/>
                                </div>
                            );
                        } else if (value.picks_available > 0 && (value.user_skin_picks.pick_1 === null && value.user_skin_picks.pick_2 === null && value.user_skin_picks.pick_3 === null)) {
                            return (
                                <div className={'m-2'} key={`skins_${index}`}>
                                    <WeekOpened isMobile={isMobile} skin={value}/>
                                </div>
                            );
                        } else if (isUpcoming) {
                            return (
                                <div className={'m-2'} key={`skins_${index}`}>
                                    <MySkin isMobile={isMobile} skin={value}/>
                                </div>
                            );
                        } else if (isLive && !isFinished) {
                            return (
                                <div className={'m-2'} key={`skins_${index}`}>
                                    <InPlay isMobile={isMobile} skin={value}/>
                                </div>
                            );
                        } else if (isFinished) {
                            return (
                                <div className={'m-2'} key={`skins_${index}`}>
                                    <Result isMobile={isMobile} skin={value}/>
                                </div>
                            );
                        }
                    })
                }
            </div>
        );
    }

    const render = () => {
        return (
            <div className={'w-100'}>
                <Helmet>
                    <title>Sportskins - My Skins</title>
                    <meta name="description" content="Sportskins- My Skins"/>
                </Helmet>
                <LoginModal showModal={showLoginModal} setShowModal={setShowLoginModal}/>
                {isMobile ? renderMobile() : renderDesktop()}
            </div>
        );
    };

    return render();
}


MySkins.propTypes = {
    isMobile: PropTypes.bool.isRequired
};
export default MySkins;
